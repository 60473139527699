import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
    weekStart: 1,
});
export const bundledDatesRangeOptions = {
    today: { label: 'Today', range: [dayjs().startOf('day').toDate(), dayjs().endOf('day').toDate()] },
    yesterday: {
        label: 'Yesterday',
        range: [dayjs().add(-1, 'day').startOf('day').toDate(), dayjs().add(-1, 'day').endOf('day').toDate()],
    },
    'last-7-days': {
        label: 'Last week',
        range: [dayjs().add(-7, 'days').startOf('day').toDate(), dayjs().endOf('day').toDate()],
    },
    'last-30-days': {
        label: 'Last 30 days',
        range: [dayjs().add(-30, 'days').startOf('day').toDate(), dayjs().endOf('day').toDate()],
    },
    'this-month': { label: 'This month', range: [dayjs().startOf('month').toDate(), dayjs().endOf('day').toDate()] },
    'last-month': {
        label: 'Last month',
        range: [dayjs().add(-1, 'month').startOf('month').toDate(), dayjs().add(-1, 'month').endOf('month').toDate()],
    },
    'this-year': { label: 'This year', range: [dayjs().startOf('year').toDate(), dayjs().endOf('day').toDate()] },
    'last-year': {
        label: 'Last year',
        range: [dayjs().add(-1, 'year').startOf('year').toDate(), dayjs().add(-1, 'year').endOf('year').toDate()],
    },
    'this-week': {
        label: 'This week',
        range: [dayjs().startOf('week').toDate(), dayjs().endOf('day').toDate()],
    },
    'last-week': {
        label: 'Last week',
        range: [dayjs().add(-1, 'week').startOf('week').toDate(), dayjs().add(-1, 'week').endOf('week').toDate()],
    },
    'this-fortnight': {
        label: 'This fortnight',
        range: [dayjs().add(-1, 'week').startOf('week').toDate(), dayjs().endOf('day').toDate()],
    },
    'last-fortnight': {
        label: 'Last fortnight',
        range: [dayjs().add(-3, 'week').startOf('week').toDate(), dayjs().add(-2, 'week').endOf('week').toDate()],
    },
};
export function isRangeOptionsWithSection(range) {
    if (range.sectionLabel && range.ranges) {
        return true;
    }
    return false;
}
export const findMatchingDatesRangeOption = (value, rangeOptions) => {
    const rangesOptionsFlat = rangeOptions.flatMap((rangeOption) => !isRangeOptionsWithSection(rangeOption) ? rangeOption : rangeOption.ranges);
    const matchingRangeOption = rangesOptionsFlat.find((rangeOption) => {
        if (typeof rangeOption === 'string') {
            const bundledRange = bundledDatesRangeOptions[rangeOption];
            if (!bundledRange?.range[0] || !bundledRange?.range[1]) {
                return false;
            }
            return (bundledRange.range[0].getTime() === value[0]?.getTime() &&
                bundledRange.range[1].getTime() === value[1]?.getTime());
        }
        if (!rangeOption.range[0] || !rangeOption.range[1]) {
            return false;
        }
        return (rangeOption.range[0].getTime() === value[0]?.getTime() && rangeOption.range[1].getTime() === value[1]?.getTime());
    });
    if (matchingRangeOption) {
        if (typeof matchingRangeOption === 'string') {
            return bundledDatesRangeOptions[matchingRangeOption];
        }
        return matchingRangeOption;
    }
    return undefined;
};
