import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { SubTitle, Text } from 'common-components/typography';
import { Form, Icon, Input, notification, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import { Information } from 'common-components/alerts';
import _ from 'lodash';

interface IEditBusinessDetailsModalProps extends FormComponentProps {
  isOpen: any;
  onClose: () => void;
  doEditBusinessDetails: typeof dispatch.companyStore.doEditBusinessDetails;
  getCompanyRegistrationDetails: typeof dispatch.companyStore.getCompanyRegistrationDetails;
  setTemporaryRegistrationData: typeof dispatch.companyStore.setTemporaryRegistrationData;
  temporaryRegistrationDetails: typeof state.companyStore.temporaryRegistrationDetails;
}

interface IEditBusinessDetailsModalState {
  title: any;
  isLoadingLookup: boolean;
  isCreated: boolean;
  errorWithRegistrationDetails: boolean;
  newRegistrationNumber: string;
}

class EditBusinessDetailsModal extends Component<IEditBusinessDetailsModalProps, IEditBusinessDetailsModalState> {
  state = {
    title: 'Change registered business details',
    isLoadingLookup: false,
    isCreated: false,
    newRegistrationNumber: null,
    errorWithRegistrationDetails: false,
  };

  private _onCloseModal = () => {
    this.setState({
      title: 'Change registered business details',
      isLoadingLookup: false,
      isCreated: false,
      errorWithRegistrationDetails: false,
      newRegistrationNumber: null,
    });
    this.props.setTemporaryRegistrationData({});
    this.props.onClose();
  };

  private _validateRegistrationNumber = (value, callback) => {
    try {
      if (value.length > 20) {
        throw Error('Registration number needs to have less than 20 digits, including spaces');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  private _onChangeRegisteredNumber = async (event) => {
    await this._fetchRegistrationDetails(event.target.value);
  };

  private _fetchRegistrationDetails = async (registrationNumber) => {
    if (!_.isEmpty(this.props.temporaryRegistrationDetails)) {
      this.props.setTemporaryRegistrationData({});
    }
    this.setState({ errorWithRegistrationDetails: false });
    if (registrationNumber.length <= 20) {
      this.props.setTemporaryRegistrationData(registrationNumber);
    }
  };

  private _editRegistrationNumber = async () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      try {
        const businessRegistrationNumber = form.getFieldValue('newRegistrationNumber');
        await this.props.doEditBusinessDetails({ businessRegistrationNumber });
        this.setState({
          title: (
            <>
              Change registered business details successful
              <Icon type='check-circle' className='ml-x-small text-color-green' />
            </>
          ),
          isCreated: true,
          newRegistrationNumber: businessRegistrationNumber,
        });
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    }
  };

  render() {
    const { isOpen, temporaryRegistrationDetails, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width='large'>
        {this.state.isCreated ? (
          <>
            <div className='mv-medium anim-slide-left'>
              <SubTitle>New ABN/CRN</SubTitle>
              <Text size='x-large'>{this.state.newRegistrationNumber}</Text>
            </div>

            <ActionModalFooter>
              <PrimaryButton size='large' onClick={this._onCloseModal}>
                Done
              </PrimaryButton>
            </ActionModalFooter>
          </>
        ) : (
          <>
            <div className='mv-medium anim-slide-left'>
              <div>
                <Information
                  content={
                    <div>
                      <b>Your ABN will be included on the NDIS (PRODA) file & invoices.</b>
                    </div>
                  }
                />
                <div className='mt-large'>
                  <SubTitle>Country</SubTitle>
                  <Text>
                    <Select size='large' style={{ width: '200px' }} defaultValue='AU' disabled>
                      <Select.Option value='AU'>Australia</Select.Option>
                    </Select>
                  </Text>
                </div>
                <div className='mt-large'>
                  <SubTitle>ABN/ACN</SubTitle>
                  <Form.Item className='width-full'>
                    {getFieldDecorator('newRegistrationNumber', {
                      rules: [{ validator: this._validateRegistrationNumber }],
                    })(
                      <Input
                        id='registrationNumber'
                        onChange={this._onChangeRegisteredNumber}
                        size='large'
                        style={{ width: '250px' }}
                      />,
                    )}
                  </Form.Item>
                </div>
              </div>

              <ActionModalFooter>
                <SecondaryButton size='large' className='mr-medium' onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  size='large'
                  disabled={_.isEmpty(temporaryRegistrationDetails)}
                  onClick={this._editRegistrationNumber}
                >
                  Change business details
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  temporaryRegistrationDetails: state.companyStore.temporaryRegistrationDetails,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditBusinessDetails: dispatch.companyStore.doEditBusinessDetails,
  getCompanyRegistrationDetails: dispatch.companyStore.getCompanyRegistrationDetails,
  setTemporaryRegistrationData: dispatch.companyStore.setTemporaryRegistrationData,
});

export default connect(mapState, mapDispatch)(Form.create<IEditBusinessDetailsModalProps>()(EditBusinessDetailsModal));
