import { Spinner } from '@blueprintjs/core';
import { Skeleton } from 'antd';
import clsx from 'clsx';
import { ISubscriptionStatusInfo } from 'interfaces/subscription-interfaces';
import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';
import { SubscriptionStatus } from 'utilities/enum-utils';
import DescriptionSection from './DescriptionSection';
import { Button, Card, Flex, Group, Stack, Title } from '@good/ui/core';
import { SubscriptionStatusBadge } from '../subscription-management/components/SubscriptionStatusBadge';
import { CANCEL_SUBSCRIPTION_LINKS, MAIL_SUBJECT_HEADERS } from '../subscription-management/utils/constants';

type ISubscriptionStatusCardProps = {
  className?: string;
  loading?: boolean;
  currentScreen?: 'Subscription' | 'Account';
  subscriptionStatusInfo: ISubscriptionStatusInfo;
  onClickCard?: () => void;
  hasPermission?: boolean;
};

const SubscriptionStatusCard: React.FC<ISubscriptionStatusCardProps> = ({
  className,
  loading,
  subscriptionStatusInfo,
  currentScreen = 'Subscription',
  onClickCard,
  hasPermission,
}) => {
  const { companyDataLite, portalUser } = useSelector((state: IRootState) => ({
    portalUser: state.authStore.portalUser,
    companyDataLite: state.companyStore.companyDataLite,
  }));

  const subscriptionStatus = subscriptionStatusInfo?.subscriptionStatus ?? SubscriptionStatus.NON_PAYG;
  const isSubscriptionScreen = currentScreen === 'Subscription';
  const daysLeftLabel = `${subscriptionStatusInfo?.numberOfDaysLeftTrial} day${
    subscriptionStatusInfo?.numberOfDaysLeftTrial === 1 ? '' : 's'
  }`;
  const clickable = companyDataLite?.hasSubscription && hasPermission;

  return (
    <Card
      p='xl'
      withBorder
      className={clsx(!isSubscriptionScreen && clickable && 'cursor-pointer card-item-hover', className)}
      {...(clickable && { onClick: onClickCard })}
    >
      <Stack>
        <Flex justify='space-between'>
          {loading ? (
            <>
              <Skeleton title={false} paragraph={{ rows: 1 }} active />
              <Spinner size={25} />
            </>
          ) : (
            <>
              <Title order={3}>Your subscription</Title>
              <SubscriptionStatusBadge status={subscriptionStatus} />
            </>
          )}
        </Flex>

        {loading ? (
          <Skeleton title={false} paragraph={{ rows: 1, width: '100%' }} active />
        ) : (
          <DescriptionSection
            daysLeftLabel={daysLeftLabel}
            hasPermission={hasPermission}
            timezone={portalUser?.timezone as string}
            hasSubscription={companyDataLite?.hasSubscription}
            isSubscriptionScreen={isSubscriptionScreen}
            subscriptionStatusInfo={subscriptionStatusInfo}
          />
        )}

        {isSubscriptionScreen && subscriptionStatus === SubscriptionStatus.CANCELED && (
          <Group>
            <Button
              size='md'
              onClick={() =>
                open(`mailto:${CANCEL_SUBSCRIPTION_LINKS.SALES_MAIL_LINK}?subject=${MAIL_SUBJECT_HEADERS.REACTIVATE}`)
              }
            >
              Reactivate subscription
            </Button>
          </Group>
        )}
      </Stack>
    </Card>
  );
};

export default SubscriptionStatusCard;
