import React from 'react';
import { Link } from 'react-router-dom';

import { Text, UnstyledButton } from '@good/ui/core';
import { useNavbarLinkProps } from './use-navbar-link-props';
import type { NavLinkItemWithoutChildItems } from '../types';
import classes from './navbar.module.css';

type NavbarLinkProps = {
  isActive: boolean;
  item: NavLinkItemWithoutChildItems;
  onClick: (item: NavLinkItemWithoutChildItems) => void;
};

export const NavbarLink = ({ isActive, item, onClick }: NavbarLinkProps) => {
  const iconWithProps = React.cloneElement(item.icon, { className: classes.linkIcon, style: { color: 'inherit' } });
  const props = useNavbarLinkProps(item);

  return (
    <>
      <UnstyledButton
        onClick={(event) => {
          if (item.onClick) {
            event.preventDefault();
            item.onClick();
            return;
          }
          onClick(item);
        }}
        component={Link}
        to={item.link}
        data-active={isActive || undefined}
        {...props}
      >
        {iconWithProps}
        <Text component='span' fz='sm'>
          {item.label}
        </Text>
      </UnstyledButton>
    </>
  );
};
