import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom-v5-compat';

import { Box, Center, Collapse, Flex, Space } from '@good/ui/core';
import { PageHeader } from '@good/ui/templates';
import { useElementSize, useDebouncedValue } from '@good/ui/hooks';
import { ErrorBoundary, GlobalErrorMessage } from 'common-components';
import { reactTrpc } from 'utilities/react-trpc';
import { PageContainer } from 'layouts/page-container';
import { usePortalUser } from 'utilities/react-hooks/use-portal-user';
import { TimesheetsTable } from './timesheets-table';
import { TimesheetsFilters, type TimesheetsFiltersValue } from './timesheets-filters';
import { ExportAllTimesheetsButton } from './export-all-timesheets-button';
import { ExportSelectedTimesheetsButton } from './export-selected-timesheets-button';
import { useTableRowControl } from './use-table-row-control';

export const TimesheetsViewWrapped = (): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'payments.timesheets' });
  const portalUser = usePortalUser();
  const [searchParams] = useSearchParams();
  const [timesheetsRequest, setTimesheetsRequest] = useState<TimesheetsFiltersValue>({
    startDate: searchParams.get('startDate') ? new Date(searchParams.get('startDate') as string) : null,
    endDate: searchParams.get('endDate') ? new Date(searchParams.get('endDate') as string) : null,
    serviceIds: searchParams.getAll('serviceIds'),
    serviceDepartmentIds: searchParams.getAll('departmentIds'),
    teamMemberIds: searchParams.getAll('teamMemberIds'),
    employmentTypes: searchParams.getAll('employmentTypes'),
  });
  const enabled = Boolean(timesheetsRequest.startDate && timesheetsRequest.endDate);
  const { data, isLoading, hasNextPage, fetchNextPage } = reactTrpc.timesheets.infiniteList.useInfiniteQuery(
    {
      serviceProviderId: portalUser.serviceProviderId,
      startDate: timesheetsRequest.startDate?.toISOString() ?? new Date().toISOString(),
      endDate: timesheetsRequest.endDate?.toISOString() ?? new Date().toISOString(),
      serviceIds: timesheetsRequest.serviceIds,
      teamMemberUserIds: timesheetsRequest.teamMemberIds,
      employmentTypes: timesheetsRequest.employmentTypes,
      serviceDepartmentIds: timesheetsRequest.serviceDepartmentIds,
      limit: 20,
    },
    { getNextPageParam: (lastPage) => lastPage.nextCursor, enabled },
  );
  const totalCount = data?.pages[0]?.totalCount;
  const { ref: pageHeaderRef, height: pageHeaderHeight } = useElementSize();
  const { ref: containerRef, height: containerHeight } = useElementSize();
  const tableRowControl = useTableRowControl(totalCount);
  const {
    selectedRows,
    inverseSelection,
    clearRows: clearSelectedRows,
    hasSelectedRows,
    selectedRowsCount,
  } = tableRowControl;
  const tableHeight = containerHeight - pageHeaderHeight;
  const [debouncedLoading] = useDebouncedValue(isLoading, 200);
  const timesheets = data?.pages.flatMap((page) => page.data);

  const onChangeTimesheetsRequest = useCallback((value: Partial<TimesheetsFiltersValue>) => {
    setTimesheetsRequest((prev) => {
      return { ...prev, ...value };
    });
  }, []);

  return (
    <PageContainer viewHeight>
      <Box h='100%' ref={containerRef}>
        <Box ref={pageHeaderRef}>
          <PageHeader
            title={t('heading')}
            subtitle={t('description')}
            size='h1'
            actions={<ExportAllTimesheetsButton disabled={hasSelectedRows} timesheetsRequest={timesheetsRequest} />}
          />
          <Collapse in={!hasSelectedRows}>
            <Flex justify='space-between' align='center'>
              <TimesheetsFilters value={timesheetsRequest} onChange={onChangeTimesheetsRequest} />
            </Flex>
          </Collapse>
          <Collapse in={hasSelectedRows}>
            <ExportSelectedTimesheetsButton
              selectedRows={selectedRows}
              timesheetsRequest={timesheetsRequest}
              clearSelectedRows={clearSelectedRows}
              selectedRowsCount={selectedRowsCount}
              inverseSelection={inverseSelection}
            />
          </Collapse>
          <Space h='md' />
        </Box>
        <ErrorBoundary
          error={() => (
            <Center>
              <GlobalErrorMessage />
            </Center>
          )}
        >
          <TimesheetsTable
            h={tableHeight}
            totalCount={totalCount}
            timesheets={timesheets}
            hasNextPage={Boolean(hasNextPage)}
            fetchNextPage={fetchNextPage}
            loading={debouncedLoading}
            empty={!enabled}
            {...tableRowControl}
          />
        </ErrorBoundary>
      </Box>
    </PageContainer>
  );
};

export const TimesheetsView = (): JSX.Element => {
  return (
    <ErrorBoundary
      error={() => (
        <Center>
          <GlobalErrorMessage />
        </Center>
      )}
    >
      <TimesheetsViewWrapped />
    </ErrorBoundary>
  );
};
