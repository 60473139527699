import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Anchor, Box, Flex, Menu, Stack } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { SelectDatesRangeOptionMenu } from './select-dates-range-option-menu';
import { findMatchingDatesRangeOption, } from './date-range-options';
import { FilterButton } from '../filter-button';
import classes from './date-range-filter.module.css';
const getSelectedLabel = (value) => {
    const hasSelectedRange = value.every((v) => v !== null);
    if (hasSelectedRange && value[0] && value[1]) {
        if (dayjs(value[0]).isSame(value[1], 'day')) {
            if (dayjs(value[0]).isSame(dayjs(), 'day')) {
                return 'Today';
            }
            return dayjs(value[0]).format('D MMM YYYY');
        }
        return value.map((v) => dayjs(v).format('D MMM YYYY')).join(' - ');
    }
    return undefined;
};
/**
 * A filter component that allows users to date ranges from a dropdown menu and displays the selection.
 */
export const DateRangeFilter = ({ clearable = false, label, onChange: onChangeProp, value: valueProp, menuProps, rangeOptions, onClose, initialValue, onRangeOptionChange: onRangeOptionChangeProp, rangeOptionsTitle, ...buttonProps }) => {
    const [valueInternal, setValueInternal] = useState(initialValue ?? [null, null]);
    const [opened, setOpened] = useState(false);
    const [rangeOption, setRangeOption] = useState(() => {
        if (rangeOptions && initialValue) {
            return findMatchingDatesRangeOption(initialValue, rangeOptions);
        }
        return undefined;
    });
    const [date, setDate] = useState(valueProp?.[0] ?? new Date());
    const onChange = (val) => {
        setValueInternal(val);
        onChangeProp?.(val);
    };
    const onChangeRangeOption = (rangeOption) => {
        setRangeOption(rangeOption);
        setValueInternal(rangeOption.range);
        setDate(rangeOption.range[0] ?? new Date());
        onRangeOptionChangeProp?.(rangeOption);
        onChangeProp?.(rangeOption.range);
    };
    const reset = () => {
        setValueInternal([null, null]);
        onChangeProp?.([null, null]);
        setRangeOption(undefined);
        onRangeOptionChangeProp?.(undefined);
    };
    const value = valueProp ?? valueInternal;
    useEffect(() => {
        if (rangeOptions && value) {
            const matchingRangeOption = findMatchingDatesRangeOption(value, rangeOptions);
            setRangeOption(matchingRangeOption);
            if (matchingRangeOption?.range[0]) {
                setDate(matchingRangeOption?.range[0]);
            }
            onRangeOptionChangeProp?.(matchingRangeOption);
        }
    }, [value]);
    const hasSelectedRange = value.every((v) => v !== null);
    const selectedLabel = rangeOption?.label ?? getSelectedLabel(value);
    const height = [320, clearable ? 30 : 0].reduce((acc, current) => acc + current, 0);
    const scrollAreaHeight = [height, rangeOptionsTitle ? -44 : 0].reduce((acc, current) => acc + current, 0);
    return (<Menu position='bottom-start' {...menuProps} onOpen={() => setOpened(true)} onClose={() => {
            onClose?.();
            setOpened(false);
        }} closeOnItemClick={false} shadow='sm' middlewares={{
            shift: false,
        }}>
      <Menu.Target>
        <FilterButton label={label} selectedLabel={selectedLabel} opened={opened} {...buttonProps} variant={hasSelectedRange ? 'light' : 'outline'} onClick={() => { }}/>
      </Menu.Target>

      <Menu.Dropdown p={0} h={height}>
        <Flex wrap='nowrap' align='stretch' gap={0}>
          {rangeOptions && rangeOptions.length > 0 && (<Box h='100%'>
              <Flex className={classes.selectDateRangeMenu} h='100%'>
                <SelectDatesRangeOptionMenu onChange={onChangeRangeOption} rangeOptions={rangeOptions} value={rangeOption} scrollAreaHeight={scrollAreaHeight} title={rangeOptionsTitle}/>
              </Flex>
            </Box>)}
          <Box h='100%' w={1} bg='red'/>
          <Stack mx='sm' my='xs' gap='sm'>
            <DatePicker type='range' value={value} onChange={(value) => onChange([
            value[0],
            value[1]
                ? dayjs(value[1])
                    .endOf('day')
                    .toDate()
                : null,
        ])} date={date} onDateChange={setDate}/>
            {clearable && (<Anchor role='button' onClick={reset} size='xs' c={!hasSelectedRange ? 'dimmed' : buttonProps.color} td={!hasSelectedRange ? 'none' : undefined} style={{ cursor: !hasSelectedRange ? 'not-allowed' : 'pointer' }} ta='right'>
                Clear Selection
              </Anchor>)}
          </Stack>
        </Flex>
      </Menu.Dropdown>
    </Menu>);
};
