import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';

import { Button, type BoxProps } from '@good/ui/core';

type ViewBookingButtonProps = BoxProps & {
  attendanceId: string;
};

export const ViewBookingButton = ({ attendanceId }: ViewBookingButtonProps) => {
  const { t } = useTranslation('', { keyPrefix: 'payments.timesheets.table.shiftDetails' });
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Button
      size='sm'
      component='a'
      href={`/bookings/details/${attendanceId}`}
      onClick={(e) => {
        e.preventDefault();
        navigate(`/bookings/details/${attendanceId}`, {
          state: { from: { url: [location.pathname, location.search].join('/'), linkLabel: 'Timesheets' } },
        });
      }}
    >
      {t('viewBookingLabel')}
    </Button>
  );
};
