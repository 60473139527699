import { notification, Skeleton } from 'antd';
import * as H from 'history';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import SubscriptionStatusCard from 'views/account-management/components/SubscriptionStatusCard';
import CancelledSubscriptionBanner from './components/CancelledSubscriptionBanner';
import BillingEmailCard from './components/BillingEmail/BillingEmailCard';
import SupportCard from './components/SupportCard';
import { Grid, SimpleGrid, Stack } from '@good/ui/core';
import ActivityReportsCard from './components/ActivityReportsCard';

interface IOverviewPanelProps {
  history: H.History;
}

const OverviewPanel: React.FC<IOverviewPanelProps> = () => {
  const dispatch = useDispatch<IRootDispatch>();

  const { portalUser, subscriptionOverview } = useSelector((state: IRootState) => ({
    portalUser: state.authStore.portalUser,
    subscriptionOverview: state.subscriptionStore.subscriptionOverview,
  }));

  const [loading, setLoading] = useState<boolean>(false);

  const getSubscriptionOverview = async () => {
    setLoading(true);
    try {
      await dispatch.subscriptionStore.doGetSubscriptionOverview();
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e });
    }
    setLoading(false);
  };

  // Activity stats are not yet implemented
  const showActivityCard = false;

  useEffect(() => {
    getSubscriptionOverview();
  }, []);

  return loading || !subscriptionOverview ? (
    <Stack>
      <Skeleton active title />
      <Skeleton active title />
    </Stack>
  ) : (
    <Stack mt='lg'>
      {subscriptionOverview.alertBannerWarning?.isAlertBannerWarning && (
        <CancelledSubscriptionBanner
          alertBannerWarning={subscriptionOverview.alertBannerWarning}
          portalUser={portalUser}
        />
      )}

      {showActivityCard ? (
        <SimpleGrid cols={{ base: 1, lg: 2 }} spacing='lg'>
          <SubscriptionStatusCard
            subscriptionStatusInfo={subscriptionOverview.subscriptionInfo}
            currentScreen='Subscription'
          />

          <ActivityReportsCard subscriptionOverview={subscriptionOverview} />

          <BillingEmailCard
            serviceProviderId={portalUser!.serviceProviderId}
            billingEmail={subscriptionOverview.billingEmail}
          />

          <SupportCard />
        </SimpleGrid>
      ) : (
        <Grid grow gutter={{ base: 'lg' }}>
          <Grid.Col span={12}>
            <SubscriptionStatusCard
              subscriptionStatusInfo={subscriptionOverview.subscriptionInfo}
              currentScreen='Subscription'
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, lg: 6 }}>
            <BillingEmailCard
              serviceProviderId={portalUser!.serviceProviderId}
              billingEmail={subscriptionOverview.billingEmail}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, lg: 6 }}>
            <SupportCard />
          </Grid.Col>
        </Grid>
      )}
    </Stack>
  );
};

export default OverviewPanel;
