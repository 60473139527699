import { Checkbox, Empty, Input, Skeleton } from 'antd';
import BottomActionSheetV2 from 'common-components/bulk-actions/BottomActionSheetV2';
import { FilterSection } from 'common-components/filter';
import { ItemCountSheet } from 'common-components/Sheets/ItemCountSheet';
import { SubTitle, Text } from 'common-components/typography';
import * as H from 'history';
import { IBatch } from 'interfaces/booking-interfaces';
import { parse } from 'json2csv/dist/json2csv.umd';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { BillableDisplayType, FilterType, PaymentSources } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import ChangeDefaultSuccessModal from 'views/account-management/components/FinanceExportConfigurationModals/ChangeDefaultSuccessModal';
import SelectDefaultFileExportModal from 'views/account-management/components/FinanceExportConfigurationModals/SelectDefaultFileExportModal';
import { ExportFileConfigType } from 'views/account-management/utils/account-management-constants';
import { PAYMENT_TAB, SupportedFinanceExportConfigStyle } from '../../utils/constants';
import BulkActionButton from '../components/BulkActionButton';
import BatchItemRow from '../components/details/BatchItemRow';
import fileDownload from 'js-file-download';
import exportBatch from 'utilities/exportBatch/export-batch-utils';
import { InfiniteScroll } from 'components';
import { PaymentHeader } from './payment-header';

const { Search } = Input;

type ListPanelProps = {
  batchList?: typeof state.billingsStore.batchList;
  batchesFilter?: typeof state.billingsStore.batchesFilter;
  currentFilterConfig?: any;
  history?: H.History;
  isServiceProviderVCPEnabled: boolean;
  defaultExportFileState: typeof state.accountStore.defaultExportFileState;
  doFetchBatches: typeof dispatch.billingsStore.doFetchBatches;
  setBatches?: typeof dispatch.billingsStore.setBatches;
  setBatchesFilter?: typeof dispatch.billingsStore.setBatchesFilter;
  portalUser: typeof state.authStore.portalUser;
  isCheckAll: typeof state.billingsStore.isCheckAll;
  setIsCheckAll: typeof dispatch.billingsStore.setIsCheckAll;
  setSelectedBatchLineItems: typeof dispatch.billingsStore.setSelectedBatchLineItems;
  selectedBatchLineItems: typeof state.billingsStore.selectedBatchLineItems;
  financeStyleState: typeof state.accountStore.financeStyleState;
  doGetAccountingSystemList: typeof dispatch.accountStore.doGetAccountingSystemList;
  doGetDataOfMultipleBatches: typeof dispatch.billingsStore.doGetDataOfMultipleBatches;
  setFinanceStyleState: typeof dispatch.accountStore.setFinanceStyleState;
  doGetFinanceExportDefaultConfigs: typeof dispatch.accountStore.doGetFinanceExportDefaultConfigs;
};

type ListPanelState = {
  topHeight: number;
  showFilters: boolean;
  showActionSheet: boolean;
  checkAllIndicator: boolean;
  indeterminateCheck: boolean;
  isLoading: boolean;
  isSearching: boolean;
  searchString: string;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  action: string;
  openAction: boolean;
  filters: any;
  paymentSourceType: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Ignore any for this filter
  availableFilters: any[];
  isSelectModalVisible: boolean;
  isSuccessChangedModalVisible: boolean;
  selectedStyleKey: string;
  selectedBatch: any;
};

const BatchEmptyState = () => (
  <div className='flex-1 bg-white  align-center flex-column'>
    <div className=''>
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size='x2-large' color='secondary' weight='bold'>
      No Batches found.
    </Text>{' '}
    <br /> <br />
    <Text color='secondary'>All batches under this filter will appear here.</Text>
    <Text color='secondary'>Try adjusting your filter, or clicking on another view.</Text>
  </div>
);

class PayListBatchSection extends PureComponent<ListPanelProps, ListPanelState> {
  // topHeight is used to control sticky

  state = {
    topHeight: 0,
    showFilters: false,
    showActionSheet: false,
    checkAllIndicator: false,
    indeterminateCheck: false,
    isLoading: false,
    isSearching: false,
    searchString: '',
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    action: '',
    openAction: false,
    filters: [],
    paymentSourceType: PaymentSources.NDIS,
    availableFilters: [FilterType.DATE_RANGE, FilterType.BATCH_BILLING, FilterType.BATCH_AUTHOR],
    isSelectModalVisible: false,
    isSuccessChangedModalVisible: false,
    selectedStyleKey: '',
    selectedBatch: null,
  };

  // ref element for the header; used to determine the height of the header
  _headerElement = null;

  _handleHeaderHeight = () => {
    if (this._headerElement) {
      this.setState({ topHeight: this._headerElement.offsetHeight - 1 });
    }
  };

  private _applySearchFilter = (searchStr) => {
    const { batchesFilter, setBatchesFilter } = this.props;
    const newPlanPaymentsFilter = _.clone(batchesFilter);
    const existingSearchIndex = _.findIndex(newPlanPaymentsFilter, (filter: any) => filter.filter === 'search');
    if (existingSearchIndex > -1) {
      if (searchStr === '') {
        newPlanPaymentsFilter.splice(existingSearchIndex, 1);
      } else {
        newPlanPaymentsFilter[existingSearchIndex].values = searchStr;
      }
    } else {
      newPlanPaymentsFilter.push({ filter: 'search', values: searchStr });
    }
    setBatchesFilter(newPlanPaymentsFilter);
    this.setState({ isSearching: false });
  };

  _searchText = (txt) => {
    this._applySearchFilter(txt);
    this.setState({ isSearching: false, searchString: txt });
  };

  _debounceSearch = _.debounce(this._searchText, 500);

  _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    this._debounceSearch(e.target.value);
  };

  private _formatFilterQuery = (appliedFilters = this.props.batchesFilter) => {
    const requestFilter: any = {};
    _.forEach(appliedFilters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'startDate':
            requestFilter.exportedDate = [filter.values[0].toDate(), filter.values[1].toDate()];
            break;
          case 'batchAuthorIds':
            requestFilter.authorIds = _.map(filter.values, (portalUser) => {
              return portalUser.value;
            });
            break;
          case 'search':
            requestFilter.search = filter.values;
            break;
          case 'serviceIds':
            requestFilter.serviceIds = filter.values;
            break;
          case 'serviceType':
            requestFilter.serviceTypes = filter.values;
            break;
          case 'ndisCategories':
            requestFilter.supportCategoryNumbers = filter.values;
            break;
          case 'userLocationByState':
            requestFilter.bookingLocationStates = filter.values;
            break;
          case FilterType.BATCH_BILLING:
            requestFilter.isShowBillableOnly = filter.values === BillableDisplayType.BILLABLE;
        }
      }
    });
    return requestFilter;
  };

  _refreshListings = async () => {
    const { doFetchBatches, setBatches, setSelectedBatchLineItems, setIsCheckAll } = this.props;

    this.setState({
      isLoading: true,
      page: 1,
      checkAllIndicator: false,
      indeterminateCheck: false,
      showActionSheet: false,
    });

    setBatches([]);
    setSelectedBatchLineItems([]);
    setIsCheckAll(false);

    await doFetchBatches({
      ...this._formatFilterQuery(),
      paymentSourceType: this.state.paymentSourceType,
      page: this.state.page,
      pageTimestamp: this.state.pageTimestamp,
      pageSize: this.state.pageSize,
    });
    this.setState({ isLoading: false });
  };

  private _fetchMoreBatches = () => {
    const { doFetchBatches, currentFilterConfig, batchesFilter } = this.props;
    const appliedFilters = _.isEmpty(batchesFilter) ? currentFilterConfig.filters : batchesFilter;
    this.setState({ isLoading: true, page: this.state.page + 1 }, async () => {
      await doFetchBatches({
        ...this._formatFilterQuery(appliedFilters),
        paymentSourceType: this.state.paymentSourceType,
        page: this.state.page,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
      });
      this.setState({ isLoading: false });
    });
  };

  private _onCheckAll = () => {
    const { isCheckAll, setIsCheckAll, setSelectedBatchLineItems, batchList } = this.props;
    const filteredBatchesList = _.filter(batchList, (batch) => batch.numberOfRejectedInvoice < batch.numberOfInvoice);
    const newSelectedBatch = isCheckAll ? [] : [...filteredBatchesList];
    setIsCheckAll(!isCheckAll);
    setSelectedBatchLineItems(newSelectedBatch);
  };

  private _onCheckBatch = (batchDetail: IBatch) => {
    const { isCheckAll, setIsCheckAll, selectedBatchLineItems, setSelectedBatchLineItems } = this.props;
    let newSelectedBatch = [];
    const isExisted = _.filter(selectedBatchLineItems, (batch) => batch.batchId === batchDetail.batchId);
    if (isExisted.length) {
      newSelectedBatch = _.filter(selectedBatchLineItems, (batch) => batch.batchId !== batchDetail.batchId);
    } else {
      newSelectedBatch = [...selectedBatchLineItems, batchDetail];
    }
    if (isCheckAll) {
      setIsCheckAll(!isCheckAll);
    }
    setSelectedBatchLineItems(newSelectedBatch);
  };

  private _onCheck = (batchDetail: IBatch) => {
    const { selectedBatchLineItems } = this.props;
    const isSelected = _.filter(selectedBatchLineItems, (item) => item.batchId === batchDetail.batchId);
    return isSelected.length > 0;
  };

  private _exportFileToCsv = async (selectedStyleKey: string, isSetDefaultType = false) => {
    const { doGetDataOfMultipleBatches, doGetAccountingSystemList, doGetFinanceExportDefaultConfigs } = this.props;
    const { selectedBatch } = this.state;
    const payload = {
      batchIds: [selectedBatch?.batchId],
      styleKey: selectedStyleKey,
      isSetDefaultType,
    };
    const unprocessedData = await doGetDataOfMultipleBatches(payload);
    const data = _.isString(unprocessedData[0].data) ? unprocessedData[0].data : parse(unprocessedData[0].data);
    fileDownload(
      data,
      `GoodHuman-FinanceFile-${unprocessedData[0].batchNumber}.${selectedStyleKey === 'MD365' ? 'tsv' : 'csv'}`,
    );
    if (isSetDefaultType) {
      this.setState({ isSuccessChangedModalVisible: true });
      await Promise.all([doGetAccountingSystemList(), doGetFinanceExportDefaultConfigs()]);
    }
  };

  private _changeTab = (e) => {
    this.props.setBatchesFilter([]);
    this.setState(
      {
        paymentSourceType: e,
        availableFilters: [FilterType.DATE_RANGE, FilterType.BATCH_BILLING, FilterType.BATCH_AUTHOR],
        page: 1,
      },
      () => this._refreshListings(),
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Ignore any for this filter
  private _onChangeFilter = (filters: any[]) => {
    this.props.setBatchesFilter(filters);
  };

  private _onExportFinanceFile = (isSetDefaultFirstTime: boolean) => {
    const { defaultExportFileState } = this.props;
    if (isSetDefaultFirstTime) {
      this.setState({ isSelectModalVisible: true });
    } else {
      this._exportFileToCsv(defaultExportFileState.accountSystem.key);
    }
  };

  private _batchExport = async (styleKey: SupportedFinanceExportConfigStyle) => {
    const { selectedBatchLineItems, doGetDataOfMultipleBatches } = this.props;
    const batchIds = selectedBatchLineItems.map((batch) => batch.batchId);
    const response = await doGetDataOfMultipleBatches({ batchIds: batchIds, styleKey: styleKey });
    const batches = response as unknown as IBatch[];
    await exportBatch({ styleKey: styleKey, batches: batches });
  };

  //region Component Lifecycle Methods
  componentDidMount = async () => {
    // Automatically set the top height for the top panel. This is required for sticky.
    this._handleHeaderHeight();
    const {
      doFetchBatches,
      currentFilterConfig,
      batchesFilter,
      doGetAccountingSystemList,
      doGetFinanceExportDefaultConfigs,
    } = this.props;
    const appliedFilters = _.isEmpty(batchesFilter) ? currentFilterConfig.filters : batchesFilter;

    this.setState({ isLoading: true });
    await Promise.all([
      doFetchBatches({
        ...this._formatFilterQuery(appliedFilters),
        paymentSourceType: this.state.paymentSourceType,
        page: this.state.page,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
      }),
      doGetAccountingSystemList(),
      doGetFinanceExportDefaultConfigs(),
    ]);

    this.setState({ isLoading: false });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { currentFilterConfig, doFetchBatches, setBatchesFilter } = this.props;

    if (prevState.showFilters !== this.state.showFilters) {
      this._handleHeaderHeight();
    }

    if (prevProps.currentFilterConfig.key !== currentFilterConfig.key) {
      this.setState({
        isLoading: true,
        searchString: '',
        checkAllIndicator: false,
        indeterminateCheck: false,
        showActionSheet: false,
        showFilters: false,
      });
      await setBatchesFilter(currentFilterConfig.filters);
    }

    if (!_.isEqual(prevProps.batchesFilter, this.props.batchesFilter)) {
      this.setState(
        { isLoading: true, page: 1 },
        async () =>
          await doFetchBatches({
            ...this._formatFilterQuery(),
            paymentSourceType: this.state.paymentSourceType,
            page: this.state.page,
            pageSize: this.state.pageSize,
            pageTimestamp: this.state.pageTimestamp,
          }),
      );
      this.setState({ isLoading: false }, () => this._handleHeaderHeight());
    }
  };

  componentWillUnmount = () => {
    const { setSelectedBatchLineItems, setIsCheckAll, setBatches, setBatchesFilter } = this.props;
    setBatches([]);
    setIsCheckAll(false);
    setSelectedBatchLineItems([]);
    setBatchesFilter([]);
  };

  //endregion

  render() {
    const { isLoading, isSelectModalVisible, isSuccessChangedModalVisible, selectedStyleKey } = this.state;

    const {
      batchList,
      batchesFilter,
      portalUser,
      history,
      selectedBatchLineItems,
      currentFilterConfig,
      setSelectedBatchLineItems,
      financeStyleState,
      setIsCheckAll,
      defaultExportFileState,
    } = this.props;

    const hasPermission = PermissionUtils.validatePermission(
      'SetDefaultFinanceFileExportConfiguration',
      portalUser.permissions.permissionRoles,
    );
    const isSetDefaultFirstTime = hasPermission && !financeStyleState.useDefaultStyle;

    const actionExportTitle = !isSetDefaultFirstTime
      ? `Export ${defaultExportFileState.accountSystem.name} file`
      : 'Export finance file';

    const isIndeterminate =
      !isLoading && selectedBatchLineItems.length !== 0 && selectedBatchLineItems.length < batchList.length;
    const isChecked =
      !isLoading && selectedBatchLineItems.length !== 0 && selectedBatchLineItems.length === batchList.length;

    return (
      <div id='scroll' className='bg-white flex-1 width-full flex-column' style={{ position: 'relative' }}>
        {/* Do NOT remove this container div. It's required for Safari sticky to work. Why it works, I have no idea.*/}
        <div>
          <div className='booking-header' ref={(com) => (this._headerElement = com)}>
            <PaymentHeader
              title={currentFilterConfig.title}
              subtitle={currentFilterConfig.description}
              onRefresh={this._refreshListings}
              onChangeTab={this._changeTab}
              vcpEnabled={this.props.isServiceProviderVCPEnabled}
            />

            <div className='flex-row pb-medium align-center justify-between'>
              <div className='mr-x2-large' style={{ minWidth: '300px' }}>
                <Search
                  onChange={this._onEnterSearchText}
                  loading={this.state.isSearching}
                  placeholder='Author name or Batch number'
                />
              </div>
              <FilterSection
                availableFilters={this.state.availableFilters}
                filters={batchesFilter ? batchesFilter : []}
                onChangeFilter={this._onChangeFilter}
                displayTimezone={portalUser.timezone}
                containerClassName='mv-small'
              />
            </div>
          </div>

          <table className='payment-listing'>
            <thead>
              <tr>
                <th style={{ top: `${this.state.topHeight}px`, width: '5%' }} className='nowrap check-all'>
                  <Checkbox onClick={this._onCheckAll} checked={isChecked} indeterminate={isIndeterminate} />
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className='nowrap'>
                  <SubTitle>Date</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className='nowrap'>
                  <SubTitle>Author</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className='nowrap'>
                  <SubTitle>Batch</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className='nowrap text-align-left'>
                  <SubTitle containerClassName='text-align-left'>Total</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className='nowrap text-align-right' />
              </tr>
            </thead>

            <tbody>
              {!this.state.isLoading && _.isEmpty(batchList) && (
                <tr style={{ cursor: 'default' }}>
                  <td colSpan={7} style={{ borderBottom: '0px solid' }}>
                    <BatchEmptyState />
                  </td>
                </tr>
              )}

              <InfiniteScroll
                hasMore={batchList.length >= this.state.page * this.state.pageSize}
                loadMore={this._fetchMoreBatches}
                asTableRow
              >
                {_.map(batchList, (batchItem) => (
                  <React.Fragment key={batchItem.batchId}>
                    <BatchItemRow
                      history={history}
                      batchItem={batchItem}
                      onCheckBatch={this._onCheckBatch}
                      isChecked={this._onCheck(batchItem)}
                      actionExportTitle={actionExportTitle}
                      exportFinanceFile={() =>
                        this.setState(
                          { selectedBatch: batchItem, selectedStyleKey: defaultExportFileState.accountSystem.key },
                          () => this._onExportFinanceFile(isSetDefaultFirstTime),
                        )
                      }
                    />
                  </React.Fragment>
                ))}
              </InfiniteScroll>
              {this.state.isLoading && (
                <tr style={{ borderBottom: '0px solid !important' }}>
                  <td colSpan={7}>
                    <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className='anim-slide-left' />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Filler */}
        <div className='flex-1 bg-white'>&nbsp;</div>

        {Boolean(selectedBatchLineItems.length) && (
          <BottomActionSheetV2
            label='batch'
            labelPlural='batches'
            onDeselect={() => {
              setIsCheckAll(false);
              setSelectedBatchLineItems([]);
            }}
            itemCount={selectedBatchLineItems.length}
          >
            <BulkActionButton
              options={financeStyleState.configTypes}
              tabType={PAYMENT_TAB.BATCH_LISTING}
              downloadExportDefaultCsv={this._batchExport}
            />
          </BottomActionSheetV2>
        )}

        {!this.state.isLoading && !this.state.showActionSheet && Boolean(batchList.length) && (
          <ItemCountSheet itemCount={batchList.length} itemLabel='Batch' plurial='es' />
        )}

        <SelectDefaultFileExportModal
          type={ExportFileConfigType.ACCOUNT_SYSTEM}
          isSetDefaultFirstTime
          isOpen={isSelectModalVisible}
          styleOptions={financeStyleState.configTypes || []}
          selectedStyle={selectedStyleKey}
          onClose={() => this.setState({ isSelectModalVisible: false })}
          onSelect={(value) => this.setState({ selectedStyleKey: value })}
          onSave={() => {
            this.setState({ isSelectModalVisible: false });
            this._exportFileToCsv(selectedStyleKey, true);
          }}
        />

        <ChangeDefaultSuccessModal
          isSetDefaultFirstTime
          type={ExportFileConfigType.ACCOUNT_SYSTEM}
          isOpen={isSuccessChangedModalVisible}
          onClose={() => this.setState({ isSuccessChangedModalVisible: false })}
        />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  selectedBillingLineItem: state.billingsStore.selectedBillingLineItem,
  isCheckAll: state.billingsStore.isCheckAll,
  selectedBatchLineItems: state.billingsStore.selectedBatchLineItems,
  financeStyleState: state.accountStore.financeStyleState,
  defaultExportFileState: state.accountStore.defaultExportFileState,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedBillingLineItem: dispatch.billingsStore.setSelectedBillingLineItem,
  setIsCheckAll: dispatch.billingsStore.setIsCheckAll,
  setSelectedBatchLineItems: dispatch.billingsStore.setSelectedBatchLineItems,
  doGetAccountingSystemList: dispatch.accountStore.doGetAccountingSystemList,
  doGetDataOfMultipleBatches: dispatch.billingsStore.doGetDataOfMultipleBatches,
  setFinanceStyleState: dispatch.accountStore.setFinanceStyleState,
  doGetFinanceExportDefaultConfigs: dispatch.accountStore.doGetFinanceExportDefaultConfigs,
});

export default connect(mapState, mapDispatch)(PayListBatchSection);
