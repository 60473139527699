import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@good/ui/core';
import { Export } from '@good/icons';
import { notifications } from '@good/ui/notifications';
import { usePortalUser } from 'utilities/react-hooks/use-portal-user';
import { TimesheetExportStyle } from 'views/billings/utils/constants';
import { useExportTimesheets } from './use-export-timesheets';
import { type TimesheetsFiltersValue } from './timesheets-filters';

type ExportAllTimesheetsButtonProps = {
  timesheetsRequest: TimesheetsFiltersValue;
  disabled: boolean;
};

export const ExportAllTimesheetsButton = ({
  timesheetsRequest,
  disabled,
}: ExportAllTimesheetsButtonProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'payments.timesheets.actions.exportAll' });
  const portalUser = usePortalUser();
  const exportTimesheets = useExportTimesheets();

  const onExport = () => {
    const startDate = timesheetsRequest.startDate?.toISOString();
    const endDate = timesheetsRequest.endDate?.toISOString();
    if (!startDate || !endDate) {
      notifications.show({
        title: t('export.invalidDateRangeError.title'),
        message: t('export.invalidDateRangeError.message'),
        color: 'red',
      });
      return;
    }

    exportTimesheets.mutate({
      serviceProviderId: portalUser.serviceProviderId,
      startDate,
      endDate,
      serviceIds: timesheetsRequest.serviceIds,
      serviceDepartmentIds: timesheetsRequest.serviceDepartmentIds,
      teamMemberUserIds: timesheetsRequest.teamMemberIds,
      employmentTypes: timesheetsRequest.employmentTypes,
      styleKey:
        (portalUser.integrationConfig?.timesheetExport?.style as TimesheetExportStyle | undefined) ??
        TimesheetExportStyle.GOODHUMAN_DEFAULT,
    });
  };

  return (
    <Button
      size='md'
      leftSection={<Export />}
      onClick={onExport}
      loading={exportTimesheets.isLoading}
      disabled={disabled}
    >
      {t('buttonLabel')}
    </Button>
  );
};
