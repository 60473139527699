import React from 'react';

import { Anchor, Checkbox, Table, HoverCard } from '@good/ui/core';
import { type TimesheetShift } from 'utilities/react-trpc';
import { DateCell } from './date-cell';
import { TeamMemberCell } from './team-member-cell';
import { ServiceCell } from './service-cell';
import { TimeTypeCell } from './time-type-cell';
import { TravelCell } from './travel-cell';
import { DurationCell } from './duration-cell';
import { ShiftDetails } from './shift-details';

type TimesheetsRowProps = {
  shift: TimesheetShift;
  selected: boolean;
  onSelect: (id: string) => void;
};

export const TimesheetsRow = React.memo(({ shift, selected, onSelect }: TimesheetsRowProps) => {
  return (
    <Table.Tr key={shift.supportWorkerAttendanceId} bg={selected ? 'var(--mantine-color-ocean-1)' : undefined}>
      <Table.Td>
        <Checkbox
          aria-label='Select row'
          checked={selected}
          onChange={() => onSelect(shift.supportWorkerAttendanceId)}
        />
      </Table.Td>
      <DateCell shift={shift} />
      <TeamMemberCell shift={shift} />
      <ServiceCell shift={shift} />
      <TimeTypeCell shift={shift} />
      <TravelCell shift={shift} />
      <DurationCell shift={shift} />
      <Table.Td>
        <HoverCard shadow='sm'>
          <HoverCard.Target>
            <Anchor size='sm' fw={500}>
              View
            </Anchor>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <ShiftDetails shift={shift} w={340} py={4} px={0} />
          </HoverCard.Dropdown>
        </HoverCard>
      </Table.Td>
    </Table.Tr>
  );
});

TimesheetsRow.displayName = 'Row';
