import React from 'react';
import { useTranslation } from 'react-i18next';

import { Anchor, Button, Group, Paper, Text } from '@good/ui/core';
import { Export } from '@good/icons';
import { notifications } from '@good/ui/notifications';
import { usePortalUser } from 'utilities/react-hooks/use-portal-user';
import { TimesheetExportStyle } from 'views/billings/utils/constants';
import { type TimesheetsFiltersValue } from './timesheets-filters';
import { useExportTimesheets } from './use-export-timesheets';

type ExportSelectedTimesheetsButtonProps = {
  timesheetsRequest: TimesheetsFiltersValue;
  selectedRows: string[];
  clearSelectedRows: () => void;
  selectedRowsCount: number;
  inverseSelection: boolean;
};

export const ExportSelectedTimesheetsButton = ({
  timesheetsRequest,
  selectedRows,
  clearSelectedRows,
  selectedRowsCount,
  inverseSelection,
}: ExportSelectedTimesheetsButtonProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'payments.timesheets.actions' });
  const portalUser = usePortalUser();
  const exportTimesheets = useExportTimesheets({
    onSuccess: clearSelectedRows,
  });

  const onExport = () => {
    const startDate = timesheetsRequest.startDate?.toISOString();
    const endDate = timesheetsRequest.endDate?.toISOString();
    if (!startDate || !endDate) {
      notifications.show({
        title: t('export.invalidDateRangeError.title'),
        message: t('export.invalidDateRangeError.message'),
        color: 'red',
      });
      return;
    }

    exportTimesheets.mutate({
      serviceProviderId: portalUser.serviceProviderId,
      startDate,
      endDate,
      serviceIds: timesheetsRequest.serviceIds,
      serviceDepartmentIds: timesheetsRequest.serviceDepartmentIds,
      teamMemberUserIds: timesheetsRequest.teamMemberIds,
      employmentTypes: timesheetsRequest.employmentTypes,
      styleKey:
        (portalUser.integrationConfig?.timesheetExport?.style as TimesheetExportStyle | undefined) ??
        TimesheetExportStyle.GOODHUMAN_DEFAULT,
      supportWorkerAttendanceIds: selectedRows,
      invertSupportWorkerAttandanceIds: inverseSelection,
    });
  };

  return (
    <Paper bg='ocean.1' p={8} display='inline-block' pl='lg'>
      <Group gap='xl'>
        <Group gap='sm'>
          <Text c='gray.9' fw={600} size='sm'>
            {t('exportSelected.selectedItems', { count: selectedRowsCount })}
          </Text>
          <Text c='dimmed' opacity={0.2}>
            |
          </Text>
          <Anchor fz={14} onClick={clearSelectedRows}>
            {t('exportSelected.clearSelection')}
          </Anchor>
        </Group>
        <Button size='md' leftSection={<Export />} onClick={onExport} loading={exportTimesheets.isLoading}>
          {t('exportSelected.buttonLabel')}
        </Button>
      </Group>
    </Paper>
  );
};
