import React, { useCallback, useState } from 'react';
import { Combobox, useCombobox, ScrollArea, Text, Stack, Flex, Box, Center, Anchor, Transition, } from '@mantine/core';
import { FilterOption } from './filter-option';
import { FilterButton } from './filter-button';
import { FilterSearchInput } from './filter-search-input';
import { InfiniteScrollCheckbox } from './infinite-scroll-checkbox';
import { CheckboxSkeletonStack } from './checkbox-skeleton';
const getSelectedLabel = (value, items) => {
    if (value.length === 1) {
        const item = items.find((item) => item.key === value[0]);
        return item ? item.label : '';
    }
    if (value.length > 1) {
        return `${value.length} items selected`;
    }
    return undefined;
};
/**
 * #### A filter component that allows users to select multiple options from a dropdown menu.
 *
 * ```tsx
 * import { Filter } from '@good/ui/templates';
 * ```
 */
export const Filter = ({ clearable = false, hideLabels = false, items, label, mahDropdown = 200, onChange, searchable = false, searchInputProps, search: searchProp, onSearchChange: onSearchChangeProp, title, topSection, value: valueProp, width = 324, comboboxProps, hasMore = false, loadMore, onClose, loading, ...buttonProps }) => {
    const [valueInternal, setValueInternal] = useState(valueProp ?? []);
    const [searchInternal, setSearchInternal] = useState('');
    const [opened, setOpened] = useState(false);
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
        onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
    });
    const search = searchProp ?? searchInternal;
    const handleValueSelect = (val) => {
        const newValue = value.includes(val) ? value.filter((v) => v !== val) : [...value, val];
        setValueInternal(newValue);
        onChange?.(newValue);
    };
    const onSearchChange = useCallback((val) => {
        reset();
        setSearchInternal(val);
        onSearchChangeProp?.(val);
    }, []);
    const reset = () => {
        setValueInternal([]);
        combobox.resetSelectedOption();
        onChange?.([]);
    };
    const value = valueProp ?? valueInternal;
    const selectedLabel = getSelectedLabel(value, items);
    const hasTopSectionContent = clearable || searchable || Boolean(title) || Boolean(topSection);
    const hasSelectedItems = value.length > 0;
    const comboboxItems = items
        ?.filter((item) => (searchable === 'implicit' ? item.label.toLowerCase().includes(search.toLowerCase()) : true))
        ?.map((item) => (<FilterOption value={value} item={item} color={buttonProps.color} key={item.key} hideLabel={hideLabels} maw={width - 32}/>));
    return (<Combobox position='bottom-start' shadow='sm' {...comboboxProps} store={combobox} onOptionSubmit={handleValueSelect} width={width} onOpen={() => setOpened(true)} onClose={() => {
            onClose?.();
            setOpened(false);
        }} middlewares={{
            shift: false,
        }}>
      <Combobox.DropdownTarget>
        <FilterButton label={label} selectedLabel={selectedLabel} opened={opened} {...buttonProps} variant={value.length > 0 ? 'light' : 'outline'} color={value.length > 0 ? buttonProps.color : 'gray'} onClick={() => combobox.toggleDropdown()}/>
      </Combobox.DropdownTarget>

      <Transition mounted={opened} transition='fade' duration={100} timingFunction='ease'>
        {(styles) => (<Combobox.Dropdown style={styles}>
            {hasTopSectionContent && (<Stack mx='sm' my='xs' gap='sm'>
                {title && (<Text fw={700} size='sm'>
                    {title}
                  </Text>)}
                {(searchable || clearable) && (<Flex gap='xs' align='center'>
                    <Box flex={2}>
                      {searchable && (<FilterSearchInput {...searchInputProps} value={search} onChange={onSearchChange}/>)}
                    </Box>
                    {clearable && (<Center flex={1} h='100%'>
                        <Anchor role='button' onClick={reset} size='xs' c={!hasSelectedItems ? 'dimmed' : buttonProps.color} td={!hasSelectedItems ? 'none' : undefined} style={{ cursor: !hasSelectedItems ? 'not-allowed' : 'pointer' }}>
                          Clear Selection
                        </Anchor>
                      </Center>)}
                  </Flex>)}
                {topSection}
              </Stack>)}
            <Combobox.Options>
              <ScrollArea.Autosize type='scroll' mah={mahDropdown} scrollbars='y' h={loading ? mahDropdown : undefined}>
                {loading ? (<CheckboxSkeletonStack />) : (<InfiniteScrollCheckbox hasMore={hasMore} loadMore={loadMore}>
                    {comboboxItems}
                  </InfiniteScrollCheckbox>)}
              </ScrollArea.Autosize>
            </Combobox.Options>
          </Combobox.Dropdown>)}
      </Transition>
    </Combobox>);
};
