import React, { useState } from 'react';
import { reactTrpc, ServiceList } from 'utilities/react-trpc';
import { Filter, FilterItem } from '@good/ui/templates';
import { usePortalUser } from 'utilities/react-hooks/use-portal-user';
import { useTranslation } from 'react-i18next';
import { useDebouncedValue } from '@good/ui/hooks';

type ServiceFilterProps = {
  value?: string[];
  onChange?: (selectedServices: string[]) => void;
  onClose?: () => void;
};

export const ServiceFilter: React.FC<ServiceFilterProps> = ({ value: valueProp, onChange: onChangeProp, onClose }) => {
  const { t } = useTranslation('', { keyPrefix: 'filters.service' });
  const portalUser = usePortalUser();
  const [valueInternal, setValueInternal] = useState(valueProp ?? []);
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebouncedValue(search, 500);
  const services = reactTrpc.services.service.list.useInfiniteQuery(
    {
      limit: 25,
      filters: {
        searchTerm: debouncedSearch,
      },
      serviceProviderId: portalUser.serviceProviderId,
    },
    { getNextPageParam: (lastPage) => lastPage.nextCursor },
  );
  const value = valueProp ?? valueInternal;

  const onChange = (selectedServices: string[]) => {
    setValueInternal(selectedServices);
    onChangeProp?.(selectedServices);
  };

  const mapServices = (services: ServiceList[]): FilterItem[] => {
    return services.flatMap((serviceList) =>
      serviceList.items.map((service) => ({
        key: service.serviceId,
        label: service.serviceName,
      })),
    );
  };

  return (
    <Filter
      items={mapServices(services.data?.pages ?? [])}
      label={t('label')}
      searchable='controlled'
      search={search}
      onSearchChange={setSearch}
      clearable
      value={value}
      onChange={onChange}
      title={t('title')}
      hasMore={services.hasNextPage}
      loadMore={async () => {
        await services.fetchNextPage();
      }}
      onClose={onClose}
      loading={services.isLoading}
    />
  );
};
