import React from 'react';

import { Table, Skeleton } from '@good/ui/core';

export const TimesheetsRowSkeleton = () => {
  return (
    <Table.Tr>
      <Table.Td>
        <Skeleton h={20} w={20} />
      </Table.Td>
      {Array.from({ length: 6 }).map((_, index) => (
        <Table.Td key={index}>
          <Skeleton h={38} />
        </Table.Td>
      ))}
      <Table.Td>
        <Skeleton h={20} w={50} />
      </Table.Td>
    </Table.Tr>
  );
};
