import React from 'react';
import { Group, Skeleton, Stack } from '@mantine/core';
export const CheckboxSkeleton = () => {
    return (<Group gap='xs'>
      <Skeleton h={18} w={18}/>
      <Skeleton h={28} w='100%' flex={1}/>
    </Group>);
};
export const CheckboxSkeletonStack = () => {
    return (<Stack my={2} gap='xs' mx={7}>
      <CheckboxSkeleton />
      <CheckboxSkeleton />
      <CheckboxSkeleton />
      <CheckboxSkeleton />
      <CheckboxSkeleton />
    </Stack>);
};
