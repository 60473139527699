import React from 'react';
import { Checkbox, Combobox, Group, Center, Paper, Text } from '@mantine/core';
/**
 * Renders a single option for the filter component.
 * Must be used as a child of the Combobox component.
 */
export const FilterOption = ({ value, item, color, hideLabel, maw }) => {
    return (<Combobox.Option value={item.key} active={value.includes(item.key)} px={8}>
      <Group gap={8} h={26} align='center' wrap='nowrap' maw={maw}>
        <Checkbox checked={value.includes(item.key)} onChange={() => { }} aria-label={item.label} color={color} size='sm'/>
        {item.leftSection}
        {item.icon && (<Paper withBorder w={20} h={20} miw={20}>
            <Center h='100%' w='100%' fz={10}>
              {item.icon}
            </Center>
          </Paper>)}
        {!hideLabel && (<Text component='span' truncate='end'>
            {item.label}
          </Text>)}
      </Group>
    </Combobox.Option>);
};
