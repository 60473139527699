import React from 'react';
import { format } from 'date-fns';

import { type TextProps, Text } from '@good/ui/core';
import { TimesheetShift, TimesheetShiftComponent } from 'utilities/react-trpc';

type ShiftTimeRangeLabelProps = TextProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any --- any is the expected & valid type for the component prop
  component?: any;
  shift: TimesheetShift | TimesheetShiftComponent;
};

const isDateOnTheHour = (date: string | Date) => {
  return new Date(date).getMinutes() === 0;
};

export const ShiftTimeRangeLabel = ({ shift, ...textProps }: ShiftTimeRangeLabelProps) => {
  const { startDateTime, endDateTime } = shift;

  return (
    <Text {...textProps}>
      {format(new Date(startDateTime), isDateOnTheHour(startDateTime) ? 'h aaa' : 'h:mm aaa')} –{' '}
      {format(new Date(endDateTime), isDateOnTheHour(endDateTime) ? 'h aaa' : 'h:mm aaa')}
    </Text>
  );
};
