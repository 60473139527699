import React from 'react';

import { PageHeader } from '@good/ui/templates';
import { ActionIcon, Tooltip, Tabs } from '@good/ui/core';
import { RefreshCw } from '@good/icons';
import { PaymentSources } from 'utilities/enum-utils';

type PaymentHeaderProps = {
  title: string;
  subtitle: string;
  onRefresh: () => void;
  onChangeTab: (tab: string | null) => void;
  vcpEnabled: boolean;
};

export const PaymentHeader = ({ title, subtitle, onRefresh, onChangeTab, vcpEnabled }: PaymentHeaderProps) => {
  return (
    <>
      <PageHeader
        title={title}
        subtitle={subtitle}
        actions={
          <Tooltip label='Refresh this view' color='lavender.9' position='left' withArrow>
            <ActionIcon size='xl' onClick={onRefresh} variant='default'>
              <RefreshCw />
            </ActionIcon>
          </Tooltip>
        }
      />
      <Tabs defaultValue={PaymentSources.NDIS} onChange={onChangeTab} mb='lg'>
        <Tabs.List>
          <Tabs.Tab value={PaymentSources.NDIS}>{PaymentSources.NDIS}</Tabs.Tab>
          {vcpEnabled && <Tabs.Tab value={PaymentSources.VCP}>{PaymentSources.VCP}</Tabs.Tab>}
        </Tabs.List>
      </Tabs>
    </>
  );
};
