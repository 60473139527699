import React from 'react';
import { useTranslation } from 'react-i18next';

import { WriteLetter } from '@good/illustrations';
import { Center, Stack, Text } from '@good/ui/core';

export const TimesheetsTableEmpty = () => {
  const { t } = useTranslation('', { keyPrefix: 'payments.timesheets.table.empty' });

  return (
    <Center h='100%' w='100%' data-testid='timesheets-table-empty' my={100}>
      <Stack align='center'>
        <WriteLetter size='md' aria-label='Write letter' />
        <Stack align='center' gap='xs'>
          <Text fw={700} ta='center'>
            {t('title')}
          </Text>
          <Text ta='center'>{t('description')}</Text>
        </Stack>
      </Stack>
    </Center>
  );
};
