import { Form, notification } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import {
  CANCEL_SUBSCRIPTION_LINKS,
  CANCEL_SUBSCRIPTION_STEP,
  IFeedbackSurveyState,
  REASON_CANCELLATION_LIST,
} from 'views/account-management/subscription-management/utils/constants';
import CancelSubscriptionInfo from './CancelSubscriptionInfo';
import FeedbackSurveySection from './FeedbackSurveySection';
import { Button, Group } from '@good/ui/core';

interface IProps extends FormComponentProps {
  isOpen: boolean;
  onClose(): void;
  openThanksForFeedbackModal: () => void;
}

const CancelSubscriptionModal = (props: IProps) => {
  const { isOpen, onClose, form, openThanksForFeedbackModal } = props;

  const dispatch = useDispatch<IRootDispatch>();

  const { subscriptionOverview, confirmCancelInfo, portalUser } = useSelector((state: IRootState) => ({
    portalUser: state.authStore.portalUser,
    confirmCancelInfo: state.subscriptionStore.confirmCancelInfo,
    subscriptionOverview: state.subscriptionStore.subscriptionOverview,
  }));

  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState<string>(CANCEL_SUBSCRIPTION_STEP.START_CANCEL);
  const [feedbackSurveyState, setFeedbackSurveyState] = useState<IFeedbackSurveyState>({
    selectedReasons: [],
    noteMissingFunctionalities: '',
    otherNote: '',
    additionalNote: '',
  });

  const primaryOwnerInfo = subscriptionOverview?.primaryOwnerInfo;
  const isViewStartCancelSubscription = activeStep === CANCEL_SUBSCRIPTION_STEP.START_CANCEL;

  const selectedReasons = form.getFieldValue('selectedReasons') || feedbackSurveyState?.selectedReasons || [];

  const _goBack = () => {
    setFeedbackSurveyState(form.getFieldsValue());
    setActiveStep(CANCEL_SUBSCRIPTION_STEP.START_CANCEL);
  };

  const _onGetSupport = () => {
    window.open(CANCEL_SUBSCRIPTION_LINKS.GET_SUPPORT);
  };

  const _cancelMySubscription = () => {
    const formState = form.getFieldsValue();

    form.validateFields(async (error) => {
      if (error) return;

      let payload: { [key: string]: boolean | string } = {
        otherNote: formState?.otherNote || '',
        noteMissingFunctionalities: formState?.noteMissingFunctionalities || '',
        additionalNote: formState?.additionalNote || '',
      };

      REASON_CANCELLATION_LIST.forEach((reason) => {
        payload = { ...payload, [reason.field]: formState.selectedReasons.includes(reason.field) };
      });

      setIsLoading(true);
      const response = await dispatch.subscriptionStore.doCancelSubscription(payload);

      if (response) {
        await dispatch.subscriptionStore.doGetSubscriptionOverview();
        _onClose();
        openThanksForFeedbackModal();
      } else {
        notification.error({ message: 'Oops, something went wrong, please try again.' });
      }
      setIsLoading(false);
    });
  };

  const _onClose = () => {
    setFeedbackSurveyState(null);
    setActiveStep(CANCEL_SUBSCRIPTION_STEP.START_CANCEL);
    onClose();
  };

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={_onClose}
      title={
        isViewStartCancelSubscription
          ? 'Are you sure you want to cancel?'
          : `${primaryOwnerInfo?.firstName}, we’re sorry to see you go!`
      }
      canCloseOutside={true}
      verticalAlignment='center'
      width='x-large'
    >
      {isViewStartCancelSubscription ? (
        <CancelSubscriptionInfo
          timezone={portalUser?.timezone}
          confirmCancelInfo={confirmCancelInfo}
          subscriptionStatus={subscriptionOverview?.subscriptionInfo?.subscriptionStatus}
        />
      ) : (
        <FeedbackSurveySection form={form} feedbackSurveyState={feedbackSurveyState} />
      )}

      <ActionModalFooter className='mt-medium'>
        {isViewStartCancelSubscription ? (
          <Group justify='end'>
            <Button size='md' variant='outline' onClick={_onGetSupport}>
              Get support
            </Button>
            <Button
              size='md'
              color='red'
              variant='outline'
              onClick={() => setActiveStep(CANCEL_SUBSCRIPTION_STEP.FEEDBACK_SURVEY)}
            >
              Continue cancellation
            </Button>
          </Group>
        ) : (
          <Group justify='end'>
            <Button size='md' variant='subtle' onClick={_goBack}>
              Back
            </Button>
            <Button
              size='md'
              color='red'
              onClick={_cancelMySubscription}
              loading={isLoading}
              disabled={!selectedReasons.length}
            >
              Cancel my subscription
            </Button>
          </Group>
        )}
      </ActionModalFooter>
    </ActionModal>
  );
};

export default Form.create<IProps>()(CancelSubscriptionModal);
