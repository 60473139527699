import React, { useEffect, useState } from 'react';
import { Button, Group, Stack, Text } from '@good/ui/core';
import { ChartColumn, PlusSquare } from '@good/icons';
import { InsightsFlyout } from 'views/insights/components/insights-flyout/insights-flyout';
import { GridHeader } from 'common-components/grids';
import { Col, Empty } from 'antd';
import { useHistory } from 'react-router-dom';
import { RouterInputs, reactTrpc } from 'utilities/react-trpc';
import rootStore, { IRootState } from 'stores/rematch/root-store';
import { useSelector } from 'react-redux';
import { FilterType } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';
import { IFilter } from 'interfaces/filter-interfaces';
import { TeamMemberViewsAndFilters } from './components/ViewsAndFilters';
import { TeamMemberListRow, TeamMemberListRowSkeleton } from './components/TeamMemberListRow';
import { usePermissionCheck } from 'stores/hooks/common/permissions';
import { PageHeader } from '@good/ui/templates';
import { InfiniteScroll } from 'components';

export const TeamMemberList = () => {
  const portalUser = useSelector((state: IRootState) => state.authStore.portalUser);

  const workerPageFilter = useSelector((state: IRootState) => state.teamStore.workerPageFilter);
  const workerListingActiveTab = useSelector((state: IRootState) => state.teamStore.workerListingActiveTab);
  const defaultWorkerViews = useSelector((state: IRootState) => state.teamStore.defaultWorkerViews);

  const setSelectedSideNavMenuKeys = rootStore.dispatch.navigationStore.setSelectedSideNavMenuKeys;
  const setWorkerPageFilter = rootStore.dispatch.teamStore.setWorkerPageFilter;
  const setWorkerListingActiveTab = rootStore.dispatch.teamStore.setWorkerListingActiveTab;
  const fetchWorkerViews = rootStore.dispatch.teamStore.doFetchWorkerViews;

  useEffect(() => {
    // fetch custom views for the user
    void fetchWorkerViews({});
    setSelectedSideNavMenuKeys(['/team']);

    // set the active tab's filters for the page
    const activeTab = workerListingActiveTab?.filterValue ? workerListingActiveTab : defaultWorkerViews[0];
    setWorkerListingActiveTab(activeTab);
    setWorkerPageFilter(
      activeTab?.filterValue
        .filter((filter) => Boolean(filter))
        .map((filter) => {
          const [entry] = Object.entries(filter);
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- the first entry should always be present
          const [key, value = []] = entry!;
          return {
            filter: key,
            values: value,
            selectionLabel: CommonUtils.getFilterText(key, value as string[]),
          };
        }) ?? [],
    );
  }, [
    setSelectedSideNavMenuKeys,
    setWorkerPageFilter,
    fetchWorkerViews,
    workerListingActiveTab,
    defaultWorkerViews,
    setWorkerListingActiveTab,
  ]);

  const filters = mapViewFiltersToTeamMemberSearchFilters(workerPageFilter);
  const teamMemberQuery = reactTrpc.teamMembers.infiniteList.useInfiniteQuery(
    {
      limit: 25,
      filters,
      serviceProviderId: portalUser?.serviceProviderId ?? '',
    },
    { getNextPageParam: (lastPage) => lastPage.nextCursor },
  );

  if (!portalUser) return null;

  return (
    <div className='h-full flex flex-col'>
      <TeamMemberListHeader />
      <TeamMemberViewsAndFilters />

      <ColumnHeaders />
      <InfiniteScroll
        hasMore={teamMemberQuery.hasNextPage ?? false}
        loadMore={async () => {
          await teamMemberQuery.fetchNextPage();
        }}
      >
        <div className='h-full w-full overflow-y-auto overflow-x-hidden'>
          {teamMemberQuery.isLoading ? (
            <Stack gap={0}>
              <TeamMemberListRowSkeleton />
              <TeamMemberListRowSkeleton />
              <TeamMemberListRowSkeleton />
            </Stack>
          ) : teamMemberQuery.data?.pages[0]?.items.length ? (
            teamMemberQuery.data.pages.flatMap(({ items }) =>
              items.map((item) => <TeamMemberListRow teamMember={item} key={item.supportWorkerId} />),
            )
          ) : (
            <Empty description='No matching team members found' className='mt-20' />
          )}
        </div>
      </InfiniteScroll>

      <div className='flex justify-end px-6 py-4'>
        <Text className='text-right'>
          {teamMemberQuery.isFetching ? '' : teamMemberQuery.hasNextPage ? '' : 'No more results'}
        </Text>
      </div>
    </div>
  );
};

const TeamMemberListHeader = () => {
  const history = useHistory();
  const hasNewTeamMemberPermission = usePermissionCheck('AddNewTeamMember');
  const [showInsightsModal, setShowInsightsModal] = useState(false);

  return (
    <PageHeader
      title='Team Members'
      subtitle='View and manage your team members.'
      actions={
        <Group gap='xs' wrap='nowrap' align='start'>
          <InsightsFlyout isOpen={showInsightsModal} onClose={() => setShowInsightsModal(false)} className='z-20' />
          <Button variant='outline' size='md' leftSection={<ChartColumn />} onClick={() => setShowInsightsModal(true)}>
            Insights
          </Button>

          {hasNewTeamMemberPermission && (
            <Button
              size='md'
              leftSection={<PlusSquare />}
              onClick={() => history.push('/team/invite-member')}
              className='w-52'
            >
              Add team members
            </Button>
          )}
        </Group>
      }
    />
  );
};

const ColumnHeaders = () => (
  <div className='mt-4'>
    <GridHeader bordered>
      <Col span={7}>
        <Text fw={600}>Team Member</Text>
      </Col>
      <Col span={3}>
        <Text fw={600}>Location</Text>
      </Col>
      <Col span={6}>
        <Text fw={600}>Availability Requests</Text>
      </Col>
      <Col span={8}>
        <Text fw={600}>Alerts</Text>
      </Col>
    </GridHeader>
  </div>
);

const mapViewFiltersToTeamMemberSearchFilters = (
  filters: IFilter[],
): RouterInputs['teamMembers']['infiniteList']['filters'] => {
  function getFilterValue<T = string[]>(filterType: FilterType) {
    return filters.find((filter) => filter.filter === filterType)?.values as T | undefined;
  }

  return {
    searchTerm: getFilterValue(FilterType.SEARCH),
    teamMemberIds: getFilterValue(FilterType.WORKER)?.length
      ? getFilterValue<{ value: string }[]>(FilterType.WORKER)?.map(
          (selectedValue: { value: string }) => selectedValue.value,
        )
      : undefined,
    serviceIds: getFilterValue(FilterType.SERVICE)?.length ? getFilterValue(FilterType.SERVICE) : undefined,
    alertTypes: getFilterValue(FilterType.WORKER_ALERTS)?.length ? getFilterValue(FilterType.WORKER_ALERTS) : undefined,
    gender: getFilterValue(FilterType.GENDER)?.length ? getFilterValue(FilterType.GENDER) : undefined,
    specialities: getFilterValue(FilterType.SPECIALITIES)?.length ? getFilterValue(FilterType.SPECIALITIES) : undefined,
    qualifications: getFilterValue(FilterType.QUALIFICATIONS)?.length
      ? getFilterValue(FilterType.QUALIFICATIONS)
      : undefined,
    interests: getFilterValue(FilterType.INTEREST)?.length ? getFilterValue(FilterType.INTEREST) : undefined,
    languages: getFilterValue(FilterType.LANGUAGES)?.length ? getFilterValue(FilterType.LANGUAGES) : undefined,
    religions: getFilterValue(FilterType.RELIGIONS)?.length ? getFilterValue(FilterType.RELIGIONS) : undefined,
    locationState: getFilterValue(FilterType.USER_LOCATION_BY_STATE)?.length
      ? getFilterValue(FilterType.USER_LOCATION_BY_STATE)
      : undefined,
    availabilityChangeRequested: getFilterValue(FilterType.AVAILABILITY_CHANGE_REQUESTS)?.includes('YES'),
  };
};
