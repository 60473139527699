import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { Box, Stack, Group, Avatar, Text, Divider, Flex, type BoxProps, Paper } from '@good/ui/core';
import { type TimesheetShift } from 'utilities/react-trpc';
import { ShiftComponentsTable } from './shift-components-table';
import { ViewBookingButton } from './view-booking-button';
import { ShiftTimeRangeLabel } from '../shift-time-range-label';

type ShiftDetailsProps = BoxProps & {
  shift: TimesheetShift;
};

export const ShiftDetails = ({ shift, ...boxProps }: ShiftDetailsProps) => {
  const { t } = useTranslation('', { keyPrefix: 'payments.timesheets.table.shiftDetails' });

  const { supportWorkerDetails, attendanceId } = shift;

  return (
    <Box {...boxProps}>
      <Stack gap={22}>
        <Stack>
          <Group gap={8}>
            <Avatar
              src={supportWorkerDetails?.avatarUrl ?? undefined}
              name={supportWorkerDetails?.displayName ?? undefined}
              radius='md'
              size={32}
            />
            <Box>
              <Text fw={600} size='sm' lh={1.2}>
                {supportWorkerDetails?.firstName} {supportWorkerDetails?.lastName}
              </Text>
              <Text size='xs' fw={500} c='dimmed' lh={1.2}>
                {format(new Date(shift.startDateTime), 'd LLL yyyy')} •{' '}
                <ShiftTimeRangeLabel shift={shift} inherit component='span' />
              </Text>
            </Box>
          </Group>
          <Box>
            <Stack gap={2}>
              <Text size='sm' fw={600}>
                {t('booking.heading')}
              </Text>
              <Text size='sm' fw={500} c='dimmed'>
                {shift.bookingReferenceId}
              </Text>
            </Stack>
          </Box>
          <Box>
            <Stack gap={2}>
              <Text size='sm' fw={600}>
                {t('customer.heading')}
              </Text>
              <Text size='sm' fw={500} c='dimmed'>
                {shift.customerDetails?.displayName}
              </Text>
            </Stack>
          </Box>
          <Box>
            <Stack gap={2}>
              <Text size='sm' fw={600}>
                {t('department.heading')}
              </Text>
              <Text size='sm' fw={500} c='dimmed'>
                {shift.serviceDetails?.serviceDepartmentName}
              </Text>
            </Stack>
          </Box>
          <Box>
            <Stack gap={8}>
              <Text size='sm' fw={600}>
                {t('shiftComponents.heading')}
              </Text>
              <Paper withBorder>
                <ShiftComponentsTable shift={shift} />
              </Paper>
            </Stack>
          </Box>
        </Stack>
        <Divider />
        <Flex justify='end'>{attendanceId && <ViewBookingButton attendanceId={attendanceId} />}</Flex>
      </Stack>
    </Box>
  );
};
