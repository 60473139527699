import React from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from '@good/ui/core';
import { type TimesheetShift } from 'utilities/react-trpc';
import { ShiftComponentRow } from './shift-component-row';

type ShiftComponentsTableProps = {
  shift: TimesheetShift;
};

export const ShiftComponentsTable = ({ shift }: ShiftComponentsTableProps) => {
  const { t } = useTranslation('', { keyPrefix: 'payments.timesheets.table.shiftDetails.shiftComponents.table' });

  return (
    <Table verticalSpacing='sm'>
      <Table.Thead>
        <Table.Tr tt='uppercase' fz={11} lh={1.2} c='dimmed'>
          <Table.Th fw={600}>{t('time')}</Table.Th>
          <Table.Th fw={600}>{t('timeType')}</Table.Th>
          <Table.Th fw={600}>{t('duration')}</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {shift.shiftComponents.map((shiftComponent, index) => (
          <ShiftComponentRow key={index} shiftComponent={shiftComponent} />
        ))}
      </Table.Tbody>
    </Table>
  );
};
