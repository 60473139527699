import { FilterType } from 'utilities/enum-utils';

export const ROSTER_COMMON_CONFIG = {
  pageSize: 250,
};

export const AVAILABLE_FILTERS: FilterType[] = [
  FilterType.CUSTOMER,
  FilterType.WORKER,
  FilterType.SERVICE,
  FilterType.BOOKING_STATUS,
  FilterType.RECURRING,
  FilterType.PAYMENT_STATUS,
  FilterType.SHIFT_SLOT_STATUS,
  FilterType.FUNDING_SOURCES,
  FilterType.BOOKING_ERROR_TYPES,
  FilterType.SHOW_ARCHIVED_BOOKINGS,
  FilterType.PINNED_ALERTS,
  FilterType.BOOKING_TYPES,
  FilterType.SERVICE_TYPE,
  FilterType.LOCATION_BY_SUBURBS,
];
