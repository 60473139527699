import React from 'react';
import { Box, Menu } from '@mantine/core';
import { bundledDatesRangeOptions, isRangeOptionsWithSection, } from '../date-range-options';
import classes from '../date-range-filter.module.css';
export const SelectDateRangeItem = ({ rangeOption, value, onChange, first }) => {
    if (isRangeOptionsWithSection(rangeOption)) {
        return (<Box mt={!first ? 'xs' : undefined}>
        <Menu.Label fw={700} tt='uppercase' fz={10} pb={0}>
          {rangeOption.sectionLabel}
        </Menu.Label>
        {rangeOption.ranges.map((range) => (<SelectDateRangeItem rangeOption={range} value={value} onChange={onChange}/>))}
      </Box>);
    }
    if (typeof rangeOption === 'string') {
        const item = bundledDatesRangeOptions[rangeOption];
        return (<Menu.Item className={classes.rangeOption} key={item.label} onClick={(e) => {
                e.preventDefault();
                onChange(item);
            }} c={value?.label !== item.label ? 'dark.3' : undefined} data-active={value?.label === item.label || undefined}>
        {item.label}
      </Menu.Item>);
    }
    return (<Menu.Item className={classes.rangeOption} key={rangeOption.label} onClick={(e) => {
            e.preventDefault();
            onChange(rangeOption);
        }} c={value?.label !== rangeOption.label ? 'dark.3' : undefined} data-active={value?.label === rangeOption.label || undefined}>
      {rangeOption.label}
    </Menu.Item>);
};
