import { type UnstyledButtonProps, useComputedColorScheme, useMantineTheme } from '@good/ui/core';
import type { NavLinkItem } from '../types';
import classes from './navbar.module.css';

export function useNavbarLinkProps(item: NavLinkItem): UnstyledButtonProps {
  const theme = useMantineTheme();
  const computedColorScheme = useComputedColorScheme('light', {
    getInitialValueInEffect: true,
  });
  const itemColor = item.color
    ? computedColorScheme === 'dark'
      ? theme.colors[item.color]?.[2]
      : theme.colors[item.color]?.[7]
    : undefined;

  return {
    className: classes.link,
    py: 'xs',
    pl: 'sm',
    pr: 'xs',
    c: itemColor,
  };
}
