import React, { useState } from 'react';
import { reactTrpc, ServiceDepartments } from 'utilities/react-trpc';
import { Filter, FilterItem } from '@good/ui/templates';
import { usePortalUser } from 'utilities/react-hooks/use-portal-user';
import { useTranslation } from 'react-i18next';

type ServiceDepartmentFilterProps = {
  value?: string[];
  onChange?: (selectedDepartments: string[]) => void;
  onClose?: () => void;
};

export const ServiceDepartmentFilter: React.FC<ServiceDepartmentFilterProps> = ({
  value: valueProp,
  onChange: onChangeProp,
  onClose,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'filters.department' });
  const portalUser = usePortalUser();
  const [valueInternal, setValueInternal] = useState(valueProp ?? []);
  const { data: serviceDepartments, isLoading } = reactTrpc.serviceProvider.serviceDepartments.list.useQuery({
    serviceProviderId: portalUser.serviceProviderId,
  });
  const value = valueProp ?? valueInternal;

  const onChange = (selectedDepartments: string[]) => {
    setValueInternal(selectedDepartments);
    onChangeProp?.(selectedDepartments);
  };

  const mapServiceDepartments = (serviceDepartments: ServiceDepartments[]): FilterItem[] =>
    serviceDepartments.map((department) => ({
      label: department.serviceDepartmentName ?? '',
      key: department.serviceDepartmentId,
    }));

  return (
    <Filter
      items={mapServiceDepartments(serviceDepartments ?? [])}
      label={t('label')}
      searchable='implicit'
      clearable
      loading={isLoading}
      value={value}
      onChange={onChange}
      title={t('title')}
      onClose={onClose}
    />
  );
};
