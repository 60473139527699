import React from 'react';
import { Link } from 'react-router-dom';

import { Text, useComputedColorScheme, useMantineTheme, UnstyledButton, Divider } from '@good/ui/core';
import type { NavChildLinkItem } from '../types';
import classes from './navbar.module.css';

type NavbarChildLinkProps = {
  isActive: boolean;
  item: NavChildLinkItem;
  onClick: (item: NavChildLinkItem) => void;
};

export const NavbarChildLink = ({ isActive, item, onClick }: NavbarChildLinkProps) => {
  const theme = useMantineTheme();
  const computedColorScheme = useComputedColorScheme('light', {
    getInitialValueInEffect: true,
  });
  const itemColor = item.color
    ? computedColorScheme === 'dark'
      ? theme.colors[item.color]?.[2]
      : theme.colors[item.color]?.[7]
    : undefined;

  return (
    <UnstyledButton
      component={Link}
      className={classes.childLinkParent}
      data-active={isActive || undefined}
      ml={-1}
      to={item.link}
      onClick={(event) => {
        if (item.onClick) {
          event.preventDefault();
          item.onClick();
          return;
        }
        onClick(item);
      }}
      mt={item.divider ? 16 : 0}
      pos='relative'
      td='none'
    >
      {item.divider && <Divider pos='absolute' w='100%' top={-8} />}
      <Text
        className={classes.childLink}
        px='sm'
        py={4}
        mx={8}
        my={2}
        c={itemColor}
        lh={1.25}
        fz='sm'
        data-active={isActive || undefined}
      >
        {item.label}
      </Text>
    </UnstyledButton>
  );
};
