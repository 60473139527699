import React from 'react';

import { Box, ScrollArea, Table } from '@good/ui/core';
import { InfiniteScroll } from 'components';
import { type TimesheetShift } from 'utilities/react-trpc';
import { TimesheetsRow, TimesheetsRowSkeleton } from './timesheets-row';
import { TimesheetsTableHeader } from './timesheets-table-header';
import { TimesheetsTableEmpty } from './timesheets-table-empty';
import { TimesheetsTableFooter } from './timesheets-table-footer';
import { TableRowControl } from '../use-table-row-control';

type TimesheetsTableProps = TableRowControl & {
  h: number;
  totalCount: number | undefined;
  timesheets: TimesheetShift[] | undefined;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  loading: boolean;
  empty: boolean;
};

export const TimesheetsTable = ({
  h,
  totalCount,
  timesheets,
  empty,
  loading,
  hasNextPage,
  fetchNextPage,
  ...tableRowControl
}: TimesheetsTableProps): JSX.Element => {
  const { selectedRows, inverseSelection, toggleRows, toggleRow, hasSelectedRows, allSelected } = tableRowControl;

  return (
    <Box>
      <ScrollArea pr='xs' h={h - 56}>
        <Box miw={900}>
          <Table verticalSpacing='sm' horizontalSpacing='sm' stickyHeader>
            <TimesheetsTableHeader
              toggleAll={toggleRows}
              timesheets={timesheets}
              hasSelectedRows={hasSelectedRows}
              allSelected={allSelected}
            />
            <Table.Tbody>
              <InfiniteScroll
                asTableRow
                hasMore={Boolean(hasNextPage)}
                loadMore={fetchNextPage}
                customLoader={Array.from({ length: 3 }).map((_, index) => <TimesheetsRowSkeleton key={index} />)}
              >
                {!empty &&
                  loading &&
                  Array.from({ length: 12 }).map((_, index) => <TimesheetsRowSkeleton key={index} />)}
                {timesheets?.map((shift) => {
                  return (
                    <TimesheetsRow
                      key={shift.supportWorkerAttendanceId}
                      shift={shift}
                      selected={
                        inverseSelection
                          ? !selectedRows.includes(shift.supportWorkerAttendanceId)
                          : selectedRows.includes(shift.supportWorkerAttendanceId)
                      }
                      onSelect={toggleRow}
                    />
                  );
                })}
              </InfiniteScroll>
            </Table.Tbody>
          </Table>
          {(empty || (timesheets && timesheets.length === 0)) && <TimesheetsTableEmpty />}
        </Box>
      </ScrollArea>
      <TimesheetsTableFooter totalCount={totalCount} h={56} />
    </Box>
  );
};
