import { Checkbox, Empty, Input, Skeleton } from 'antd';
import { FilterSection } from 'common-components/filter';
import { BottomActionSheet } from 'common-components/Sheets/BottomActionSheet';
import { ItemCountSheet } from 'common-components/Sheets/ItemCountSheet';
import { IInvoice } from 'interfaces/booking-interfaces';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FilterType, PaymentSources } from 'utilities/enum-utils';
import { SubTitle, Text } from '../../../../common-components/typography';
import { dispatch, IRootDispatch, IRootState, state } from '../../../../stores/rematch/root-store';
import InvoiceItemRow from '../components/details/InvoiceItemRow';
import MarkAsPaidActionModal from '../components/MarkAsPaidActionModal';
import RejectInvoiceActionModal from '../components/RejectInvoiceActionModal';
import RejectInvoiceLineActionModal from '../components/RejectInvoiceLineActionModal';
import { InfiniteScroll } from 'components';
import { PaymentHeader } from './payment-header';

const { Search } = Input;

interface ListPanelProps {
  invoicesList?: typeof state.billingsStore.invoiceList;
  invoicesFilter?: typeof state.billingsStore.invoicesFilter;
  currentFilterConfig?: any;
  history?: any;
  isServiceProviderVCPEnabled: boolean;
  doFetchInvoices: typeof dispatch.billingsStore.doFetchInvoices;
  setInvoices?: typeof dispatch.billingsStore.setInvoices;
  setInvoicesFilter?: typeof dispatch.billingsStore.setInvoicesFilter;
  setSelectedBillingLineItem?: typeof dispatch.billingsStore.setSelectedBillingLineItem;
  setSelectedInvoiceLineItems?: typeof dispatch.billingsStore.setSelectedInvoiceLineItems;
  setSelectedInvoice?: typeof dispatch.billingsStore.setSelectedInvoice;
  setSelectedInvoices?: typeof dispatch.billingsStore.setSelectedInvoices;
  setSelectedHistoryIds?: typeof dispatch.billingsStore.setSelectedHistoryIds;
  setSelectedRejectBillingItems: typeof dispatch.billingsStore.setSelectedRejectBillingItems;
  portalUser: typeof state.authStore.portalUser;
  selectedInvoice: typeof state.billingsStore.selectedInvoice;
  selectedInvoices: typeof state.billingsStore.selectedInvoices;
  setMarkAsPaidType: typeof dispatch.billingsStore.setMarkAsPaidType;
  setIsCheckAll: typeof dispatch.billingsStore.setIsCheckAll;
  isCheckAll: typeof state.billingsStore.isCheckAll;
}

interface ListPanelState {
  topHeight: number;
  showFilters: boolean;
  showActionSheet: boolean;
  checkAllIndicator: boolean;
  indeterminateCheck: boolean;
  isLoading: boolean;
  isSearching: boolean;
  searchString: string;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  action: string;
  openAction: boolean;
  filters: any;
  paymentSourceType: string;
  availableFilters: Array<any>;
}

const ProcessedEmptyState = () => (
  <div className='flex-1 bg-white  align-center flex-column'>
    <div className=''>
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size='x2-large' color='secondary' weight='bold'>
      No Invoices found.
    </Text>{' '}
    <br /> <br />
    <Text color='secondary'>All invoices under this filter will appear here.</Text>
    <Text color='secondary'>Try adjusting your filter, or clicking on another view.</Text>
  </div>
);

class PayListProcessedSection extends PureComponent<ListPanelProps, ListPanelState> {
  // topHeight is used to control sticky
  state = {
    topHeight: 0,
    showFilters: false,
    showActionSheet: false,
    checkAllIndicator: false,
    indeterminateCheck: false,
    isLoading: false,
    isSearching: false,
    searchString: '',
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    action: '',
    openAction: false,
    filters: [],
    paymentSourceType: PaymentSources.NDIS,
    availableFilters: [
      FilterType.DATE_RANGE,
      FilterType.CUSTOMER,
      FilterType.PAYMENT_METHODS,
      FilterType.SERVICE,
      FilterType.SERVICE_TYPE,
      FilterType.NDIS_CATEGORIES,
      FilterType.USER_LOCATION_BY_STATE,
      FilterType.REJECTION_STATUS,
    ],
  };

  _headerElement = null;

  openActionModal = ({ action, additionalData, selected = null }) => {
    const {
      setSelectedHistoryIds,
      setSelectedInvoiceLineItems,
      setSelectedInvoice,
      setSelectedRejectBillingItems,
      setMarkAsPaidType,
    } = this.props;
    if (action === 'REJECT_LINEITEM') {
      setSelectedInvoiceLineItems(additionalData);
      const selectedDatas = _.map(
        _.filter(additionalData, (data) => !data.isRejected),
        (item) => {
          return {
            billingLineItemId: item.bookingBillingLineItemId,
            attendanceId: item.attendanceId,
            historyId: item.historyId,
          };
        },
      );
      if (selected === 'ALL') {
        setSelectedRejectBillingItems(selectedDatas);
      } else if (additionalData && additionalData.length === 1 && !additionalData[0].isRejected) {
        setSelectedHistoryIds([additionalData[0].historyId]);
        setSelectedRejectBillingItems(selectedDatas);
      } else {
        setSelectedHistoryIds([]);
      }
    } else if (action === 'REJECT_INVOICE') {
      setSelectedInvoice(additionalData);
    } else if (action === 'MARK_AS_PAID') {
      setMarkAsPaidType('SINGLE');
      setSelectedInvoice(additionalData);
    }
    this.setState({ action, openAction: true });
  };

  getActionModal = () => {
    if (this.state.action === 'REJECT_LINEITEM') {
      return RejectInvoiceLineActionModal;
    } else if (this.state.action === 'REJECT_INVOICE') {
      return RejectInvoiceActionModal;
    } else if (this.state.action === 'MARK_AS_PAID') {
      return MarkAsPaidActionModal;
    } else {
      return () => <></>;
    }
  };

  closeActionModal = () => this.setState({ openAction: false });

  _handleHeaderHeight = () => {
    if (this._headerElement) {
      this.setState({ topHeight: this._headerElement.offsetHeight - 1 });
    }
  };

  private _formatFilterQuery = (appliedFilters = this.props.invoicesFilter) => {
    const requestFilter: any = {};
    _.forEach(appliedFilters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'startDate':
            requestFilter.issueDate = [filter.values[0].toDate(), filter.values[1].toDate()];
            break;
          case 'paymentMethods':
            requestFilter.paymentMethods = filter.values;
            break;
          case 'customerUserIds':
            requestFilter.customerUserIds = _.map(filter.values, (customer) => {
              return customer.value;
            });
            break;
          case 'search':
            requestFilter.search = filter.values;
            break;
          case 'serviceIds':
            requestFilter.serviceIds = filter.values;
            break;
          case 'serviceType':
            requestFilter.serviceTypes = filter.values;
            break;
          case 'ndisCategories':
            requestFilter.supportCategoryNumbers = filter.values;
            break;
          case 'userLocationByState':
            requestFilter.bookingLocationStates = filter.values;
            break;
          case 'rejectionStatus':
            requestFilter.isOnlyShowInvoiceContainRejected = !!filter.values?.length;
            break;
          default:
            break;
        }
      }
    });
    return requestFilter;
  };

  private _applySearchFilter = async (searchStr) => {
    const { invoicesFilter, setInvoicesFilter } = this.props;
    const newPlanPaymentsFilter = _.clone(invoicesFilter);
    const existingSearchIndex = _.findIndex(newPlanPaymentsFilter, (filter: any) => filter.filter === 'search');
    if (existingSearchIndex > -1) {
      if (searchStr === '') {
        newPlanPaymentsFilter.splice(existingSearchIndex, 1);
      } else {
        newPlanPaymentsFilter[existingSearchIndex].values = searchStr;
      }
    } else {
      newPlanPaymentsFilter.push({ filter: 'search', values: searchStr });
    }
    setInvoicesFilter(newPlanPaymentsFilter);
    this.setState({ isSearching: false });
  };

  _searchText = (txt) => {
    this._applySearchFilter(txt);
    this.setState({ isSearching: false, searchString: txt });
  };

  _debounceSearch = _.debounce(this._searchText, 500);

  _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    this._debounceSearch(e.target.value);
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.props.setInvoicesFilter(filters);
  };

  _refreshListings = async () => {
    const { doFetchInvoices } = this.props;
    this.setState({ isLoading: true, checkAllIndicator: false, indeterminateCheck: false, showActionSheet: false });
    this.setState({ isLoading: false, pageTimestamp: new Date(), page: 1 });

    await doFetchInvoices({
      ...this._formatFilterQuery(),
      paymentSourceType: this.state.paymentSourceType,
      page: this.state.page,
      pageTimestamp: this.state.pageTimestamp,
      pageSize: this.state.pageSize,
      isPaid: false,
    });
    this.setState({ isLoading: false });
  };

  private _fetchMorePayment = () => {
    const { doFetchInvoices } = this.props;
    this.setState({ isLoading: true, page: this.state.page + 1 }, async () => {
      await doFetchInvoices({
        ...this._formatFilterQuery(),
        paymentSourceType: this.state.paymentSourceType,
        page: this.state.page,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
        isPaid: false,
      });
      this.setState({ isLoading: false });
    });
  };

  private _changeTab = (e) => {
    this.props.setInvoicesFilter([]);
    this.setState(
      {
        paymentSourceType: e,
        availableFilters:
          e === PaymentSources.NDIS
            ? [
                FilterType.DATE_RANGE,
                FilterType.CUSTOMER,
                FilterType.PAYMENT_METHODS,
                FilterType.SERVICE,
                FilterType.SERVICE_TYPE,
                FilterType.NDIS_CATEGORIES,
                FilterType.USER_LOCATION_BY_STATE,
                FilterType.REJECTION_STATUS,
              ]
            : [FilterType.DATE_RANGE, FilterType.CUSTOMER, FilterType.REJECTION_STATUS],
      },
      () => this._refreshListings(),
    );
  };

  private _checkedInvoice = (invoiceItem: IInvoice) => {
    const { selectedInvoices } = this.props;
    const isSelected = _.filter(selectedInvoices, (invoice) => invoice.invoiceId === invoiceItem.invoiceId);
    return isSelected.length > 0;
  };

  private _onCheckInvoice = (invoiceItem: IInvoice) => {
    const { selectedInvoices, setSelectedInvoices, isCheckAll, setIsCheckAll, invoicesList } = this.props;

    let newSelectedInvoice;

    const isExisted = _.filter(selectedInvoices, (invoice) => invoice.invoiceId === invoiceItem.invoiceId);
    if (isExisted.length !== 0) {
      newSelectedInvoice = _.filter(selectedInvoices, (invoice) => invoice.invoiceId !== invoiceItem.invoiceId);
    } else {
      newSelectedInvoice = [...selectedInvoices, invoiceItem];
    }

    if (isCheckAll) {
      setIsCheckAll(false);
    } else {
      const unRejectedInvoices = _.filter(invoicesList, (invoice) => {
        const items = invoice.items;
        const rejectedItems = _.filter(items, (item) => item.isRejected);
        return rejectedItems.length < items.length;
      });

      if (unRejectedInvoices.length === newSelectedInvoice.length) {
        setIsCheckAll(true);
      }
    }

    setSelectedInvoices(newSelectedInvoice);
  };

  private _onCheckAll = () => {
    const { setSelectedInvoices, invoicesList, setIsCheckAll, isCheckAll } = this.props;
    let newSelectedInvoice;

    //filter invoice list without all items rejected invoices
    const filteredInvoicesList = _.filter(invoicesList, (invoice) => {
      const items = invoice.items;
      const rejectedItems = _.filter(items, (item) => item.isRejected);
      return rejectedItems.length < items.length;
    });

    if (isCheckAll) {
      newSelectedInvoice = [];
      setIsCheckAll(false);
    } else {
      newSelectedInvoice = [...filteredInvoicesList];
      setIsCheckAll(true);
    }

    setSelectedInvoices(newSelectedInvoice);
  };

  _openMultipleActionModal = () => {
    this.props.setMarkAsPaidType('MULTIPLE');
    this.setState({ action: 'MARK_AS_PAID', openAction: true });
  };

  _onActionDeselect = () => {
    this.props.setSelectedInvoices([]);
  };

  //region Component Lifecycle Methods
  componentDidMount = async () => {
    // Automatically set the top height for the top panel. This is required for sticky.
    this._handleHeaderHeight();
    const { doFetchInvoices, currentFilterConfig, invoicesFilter } = this.props;

    const appliedFilters = _.isEmpty(invoicesFilter) ? currentFilterConfig.filters : invoicesFilter;

    this.setState({ isLoading: true });

    await doFetchInvoices({
      ...this._formatFilterQuery(appliedFilters),
      paymentSourceType: this.state.paymentSourceType,
      page: this.state.page,
      pageTimestamp: this.state.pageTimestamp,
      pageSize: this.state.pageSize,
      isPaid: false,
    });
    this.setState({ isLoading: false });
  };

  componentDidUpdate = async (prevProps) => {
    const { currentFilterConfig, doFetchInvoices, setInvoices } = this.props;

    if (prevProps.currentFilterConfig.key !== currentFilterConfig.key) {
      this.setState({
        isLoading: true,
        searchString: '',
        showFilters: false,
      });
    }

    if (prevProps.invoicesFilter !== this.props.invoicesFilter) {
      this.setState({ isLoading: true, page: 1 });
      await setInvoices([]);
      await doFetchInvoices({
        ...this._formatFilterQuery(),
        paymentSourceType: this.state.paymentSourceType,
        page: this.state.page,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
        isPaid: false,
      });
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = () => {
    const { setSelectedInvoices, setInvoices, setIsCheckAll, setInvoicesFilter } = this.props;
    setInvoicesFilter([]);
    setSelectedInvoices([]);
    setInvoices([]);
    setIsCheckAll(false);
  };

  render() {
    const { invoicesList, invoicesFilter, portalUser, history } = this.props;

    const TargetActionModal: any = this.getActionModal();
    const { currentFilterConfig } = this.props;
    const invoicesFilterHaveValue = _.filter(invoicesFilter, (filter) =>
      filter.filter === FilterType.REJECTION_STATUS ? !!filter.values?.length : true,
    );

    return (
      <div id='scroll-container' className='bg-white flex-1 width-full flex-column' style={{ position: 'relative' }}>
        <TargetActionModal
          isOpen={this.state.openAction}
          onClose={this.closeActionModal}
          refreshListings={this._refreshListings}
        />
        <div>
          <div className='booking-header' ref={(com) => (this._headerElement = com)}>
            <PaymentHeader
              title={currentFilterConfig.title}
              subtitle={currentFilterConfig.description}
              onRefresh={this._refreshListings}
              onChangeTab={this._changeTab}
              vcpEnabled={this.props.isServiceProviderVCPEnabled}
            />
            <div className='flex-row pb-medium align-center justify-between'>
              <div className='mr-x2-large' style={{ minWidth: '300px' }}>
                <Search
                  onChange={this._onEnterSearchText}
                  loading={this.state.isSearching}
                  placeholder='Name, Invoice number or Batch number'
                />
              </div>
              <FilterSection
                availableFilters={this.state.availableFilters}
                filters={invoicesFilterHaveValue ?? []}
                onChangeFilter={this._onChangeFilter}
                displayTimezone={portalUser.timezone}
                containerClassName='mv-small'
              />
            </div>
          </div>

          <table className='payment-listing'>
            <thead>
              <tr>
                <th className='nowrap check-all' style={{ top: `${this.state.topHeight}px` }}>
                  <Checkbox
                    onClick={this._onCheckAll}
                    checked={
                      !this.state.isLoading &&
                      this.props.selectedInvoices.length !== 0 &&
                      this.props.selectedInvoices.length === invoicesList.length
                    }
                    indeterminate={
                      !this.state.isLoading &&
                      this.props.selectedInvoices.length < invoicesList.length &&
                      this.props.selectedInvoices.length !== 0
                    }
                  />
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className='nowrap'>
                  <SubTitle>Date</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className='nowrap'>
                  <SubTitle>Invoice (items)</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className='nowrap'>
                  <SubTitle>Customer</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className='nowrap'>
                  <SubTitle>Batch</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className='nowrap text-align-right'>
                  <SubTitle containerClassName='text-align-right'>Total</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px`, width: '100px' }} />
                <th style={{ top: `${this.state.topHeight}px`, width: '50px' }} />
              </tr>
            </thead>

            <tbody>
              {!this.state.isLoading && _.isEmpty(invoicesList) && (
                <tr style={{ cursor: 'default' }}>
                  <td colSpan={7} style={{ borderBottom: '0px solid' }}>
                    <ProcessedEmptyState />
                  </td>
                </tr>
              )}

              <InfiniteScroll
                hasMore={invoicesList.length >= this.state.page * this.state.pageSize}
                loadMore={this._fetchMorePayment}
                asTableRow
              >
                {_.map(invoicesList, (invoiceItem) => (
                  <InvoiceItemRow
                    invoiceItem={invoiceItem}
                    history={history}
                    key={invoiceItem.invoiceId}
                    openActionModal={this.openActionModal}
                    setSelectedRejectBillingItems={this.props.setSelectedRejectBillingItems}
                    onCheckInvoice={this._onCheckInvoice}
                    isChecked={this._checkedInvoice(invoiceItem)}
                  />
                ))}
              </InfiniteScroll>
              {this.state.isLoading && (
                <tr style={{ borderBottom: '0px solid !important' }}>
                  <td colSpan={7}>
                    <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className='anim-slide-left' />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Filler */}
        <div className='flex-1 bg-white'>&nbsp;</div>

        {this.props.selectedInvoices.length > 0 && (
          <BottomActionSheet
            paymentsList={this.props.selectedInvoices}
            onDeselect={this._onActionDeselect}
            refreshPaymentListings={this._refreshListings}
            openActionModal={this._openMultipleActionModal}
            hasAdditionalButton={false}
            primaryButtonTitle='Mark as paid'
          />
        )}

        {!this.state.isLoading && !this.state.showActionSheet && invoicesList.length > 0 && (
          <ItemCountSheet itemCount={invoicesList.length} itemLabel='Invoice' />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBillingLineItem: state.billingsStore.selectedBillingLineItem,
  selectedInvoice: state.billingsStore.selectedInvoice,
  selectedInvoices: state.billingsStore.selectedInvoices,
  isCheckAll: state.billingsStore.isCheckAll,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedBillingLineItem: dispatch.billingsStore.setSelectedBillingLineItem,
  setSelectedInvoiceLineItems: dispatch.billingsStore.setSelectedInvoiceLineItems,
  setSelectedInvoice: dispatch.billingsStore.setSelectedInvoice,
  setSelectedHistoryIds: dispatch.billingsStore.setSelectedHistoryIds,
  setSelectedRejectBillingItems: dispatch.billingsStore.setSelectedRejectBillingItems,
  setSelectedInvoices: dispatch.billingsStore.setSelectedInvoices,
  setMarkAsPaidType: dispatch.billingsStore.setMarkAsPaidType,
  setIsCheckAll: dispatch.billingsStore.setIsCheckAll,
});

export default connect(mapState, mapDispatch)(PayListProcessedSection);
