import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Group, Table, Tooltip } from '@good/ui/core';
import { Info } from '@good/icons';
import { ITimesheetShiftItem } from 'interfaces/booking-interfaces';

type TimesheetsTableHeaderProps = {
  toggleAll: () => void;
  timesheets?: ITimesheetShiftItem[];
  hasSelectedRows: boolean;
  allSelected: boolean;
};

export const TimesheetsTableHeader = ({
  toggleAll,
  timesheets,
  hasSelectedRows,
  allSelected,
}: TimesheetsTableHeaderProps) => {
  const { t } = useTranslation('', { keyPrefix: 'payments.timesheets.table.header' });

  return (
    <Table.Thead>
      <Table.Tr tt='uppercase' fz={14} c='dimmed'>
        <Table.Th w={50}>
          <Checkbox
            onChange={toggleAll}
            checked={allSelected}
            indeterminate={hasSelectedRows && !allSelected}
            disabled={!timesheets?.length}
          />
        </Table.Th>
        <Table.Th fw={600}>{t('date_and_time')}</Table.Th>
        <Table.Th fw={600}>{t('team_member')}</Table.Th>
        <Table.Th fw={600}>{t('service')}</Table.Th>
        <Table.Th fw={600}>
          <Group gap={4}>
            {t('time_type.label')}
            <Tooltip label={t('time_type.tooltip')} color='lavender.9' multiline withArrow w='250' ta='center'>
              <Info />
            </Tooltip>
          </Group>
        </Table.Th>
        <Table.Th fw={600}>{t('travel')}</Table.Th>
        <Table.Th fw={600}>{t('duration')}</Table.Th>
        <Table.Th w={50}></Table.Th>
      </Table.Tr>
    </Table.Thead>
  );
};
