import React, { useState } from 'react';
import { Filter } from '@good/ui/templates';
import { useTranslation } from 'react-i18next';

type EmploymentTypeFilterProps = {
  value?: string[];
  onChange?: (selectedEmploymentTypes: string[]) => void;
  onClose?: () => void;
};

export const EmploymentTypeFilter: React.FC<EmploymentTypeFilterProps> = ({
  value: valueProp,
  onChange: onChangeProp,
  onClose,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'filters.employment' });
  const [valueInternal, setValueInternal] = useState(valueProp ?? []);
  const value = valueProp ?? valueInternal;

  const onChange = (selectedEmploymentTypes: string[]) => {
    setValueInternal(selectedEmploymentTypes);
    onChangeProp?.(selectedEmploymentTypes);
  };

  const employmentTypes = [
    { label: 'Full Time', key: 'FullTime' },
    { label: 'Part Time', key: 'PartTime' },
    { label: 'Casual', key: 'Casual' },
    { label: 'Volunteer', key: 'Volunteer' },
  ];

  return (
    <Filter
      items={employmentTypes}
      label={t('label')}
      value={value}
      onChange={onChange}
      title={t('title')}
      onClose={onClose}
    />
  );
};
