import React, { useState } from 'react';
import { reactTrpc, TeamMember } from 'utilities/react-trpc';
import { Filter, FilterItem } from '@good/ui/templates';
import { usePortalUser } from 'utilities/react-hooks/use-portal-user';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@good/ui/core';
import { useDebouncedValue } from '@good/ui/hooks';

type TeamMemberFilterProps = {
  value?: string[];
  onChange?: (selectedTeamMembers: string[]) => void;
  onClose?: () => void;
  on?: 'userId' | 'supportWorkerId';
};

export const TeamMemberFilter: React.FC<TeamMemberFilterProps> = ({
  value: valueProp,
  onChange: onChangeProp,
  onClose,
  on = 'supportWorkerId',
}) => {
  const { t } = useTranslation('', { keyPrefix: 'filters.teamMember' });
  const portalUser = usePortalUser();
  const [valueInternal, setValueInternal] = useState(valueProp ?? []);
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebouncedValue(search, 500);
  const teamMemberQuery = reactTrpc.teamMembers.infiniteList.useInfiniteQuery(
    {
      limit: 25,
      filters: {
        searchTerm: debouncedSearch,
      },
      serviceProviderId: portalUser.serviceProviderId,
    },
    { getNextPageParam: (lastPage) => lastPage.nextCursor },
  );
  const value = valueProp ?? valueInternal;

  const onChange = (selectedTeamMember: string[]) => {
    setValueInternal(selectedTeamMember);
    onChangeProp?.(selectedTeamMember);
  };

  const mapTeamMembers = (teamMemberQuery: TeamMember[]): FilterItem[] => {
    return teamMemberQuery.flatMap((teamMembers) =>
      teamMembers.items.map((teamMember) => ({
        key: teamMember[on],
        label: `${teamMember.firstName} ${teamMember.lastName}`,
        leftSection: <Avatar src={teamMember.attachmentUrl} radius='sm' size='sm' />,
      })),
    );
  };

  return (
    <Filter
      items={mapTeamMembers(teamMemberQuery.data?.pages ?? [])}
      label={t('label')}
      searchable='controlled'
      search={search}
      onSearchChange={setSearch}
      clearable
      value={value}
      onChange={onChange}
      title={t('title')}
      hasMore={teamMemberQuery.hasNextPage}
      loadMore={async () => {
        await teamMemberQuery.fetchNextPage();
      }}
      onClose={onClose}
    />
  );
};
