import moment from 'moment';
import React from 'react';
import { IConfirmCancelInfo } from 'interfaces/subscription-interfaces';
import { CANCEL_SUBSCRIPTION_LINKS } from 'views/account-management/subscription-management/utils/constants';
import { SubscriptionStatus } from 'utilities/enum-utils';
import { Alert, Button, Text, Stack } from '@good/ui/core';
import { ExternalLink } from '@good/icons';

interface IProps {
  timezone: string;
  confirmCancelInfo: IConfirmCancelInfo;
  subscriptionStatus: SubscriptionStatus;
}

const CancelSubscriptionInfo: React.FC<IProps> = ({ confirmCancelInfo, timezone, subscriptionStatus }) => {
  const hasMoreThanTenBookingsAndInvoices =
    confirmCancelInfo.totalUnfinishedBookings >= 10 && confirmCancelInfo.totalUnprocessedInvoices >= 10;

  const bookingMailtoLink =
    subscriptionStatus === SubscriptionStatus.ACTIVE
      ? `mailto:${CANCEL_SUBSCRIPTION_LINKS.SUPPORT_MAIL_LINK}`
      : `mailto:${CANCEL_SUBSCRIPTION_LINKS.SALES_MAIL_LINK}`;

  return (
    <Stack>
      {hasMoreThanTenBookingsAndInvoices ? (
        <Text>
          You still have{' '}
          <Text span fw='bold'>
            {confirmCancelInfo.totalUnfinishedBookings} unfinished bookings
          </Text>{' '}
          and
          <Text span fw='bold'>
            {' '}
            {confirmCancelInfo.totalUnprocessedInvoices} unprocessed invoices
          </Text>{' '}
          in GoodHuman. Are you sure you want to cancel?
        </Text>
      ) : (
        <Text>It looks like you haven’t gotten quite set up. Are you sure you want to cancel?</Text>
      )}

      <Text>
        If you cancel your subscription today, you and your team will be able to continue accessing your GoodHuman
        account until{' '}
        <Text span fw='bold'>
          {moment.tz(confirmCancelInfo.untilDate, timezone).format('D MMM YYYY')}.
        </Text>
      </Text>

      <Alert title='We’re here to help!'>
        <Stack>
          <Text>If you’re unsure about cancelling your account, we’d love to chat.</Text>

          <Button leftSection={<ExternalLink />} onClick={() => open(bookingMailtoLink)}>
            Book time with a GoodHuman expert
          </Button>
        </Stack>
      </Alert>
    </Stack>
  );
};

export default CancelSubscriptionInfo;
