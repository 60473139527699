import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { SetupIntentResult } from '@stripe/stripe-js';
import { Checkbox, notification } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IRootDispatch } from 'stores/rematch/root-store';
import PaymentMethodError from './PaymentMethodError';
import { Button, Group } from '@good/ui/core';

interface IAddCreditCardProps {
  isOpen?: boolean;
  onClose?: () => void;
  fetchData: () => void | Promise<void>;
}

const AddCreditCardModal = (props: IAddCreditCardProps) => {
  const { isOpen, onClose, fetchData } = props;

  const dispatch = useDispatch<IRootDispatch>();

  const stripe = useStripe();
  const elements = useElements();

  const [isDefaultCard, setIsDefaultCard] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [paymentMethodError, setPaymentMethodError] = useState(false);
  const [isLoadingPaymentElement, setIsLoadingPaymentElement] = useState(true);

  const _onClose = () => {
    setPaymentMethodError(false);
    onClose();
  };

  const _handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    setPaymentMethodError(false);
    try {
      const confirm: SetupIntentResult = await stripe.confirmSetup({ elements, redirect: 'if_required' });
      if (confirm.error) {
        setPaymentMethodError(true);
        throw Error(confirm.error.message);
      }
      if (isDefaultCard) {
        await dispatch.subscriptionStore.doSetDefaultPaymentMethod(confirm?.setupIntent?.payment_method.toString());
      }
      dispatch.subscriptionStore.setClientSecret(null);
      _onClose();
      notification.success({
        message: <Text weight='bold'>Payment method added</Text>,
        description: 'You have successfully added a new payment method.',
      });
      await fetchData();
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
    setLoading(false);
  };

  return (
    <ActionModal
      isOpen={isOpen}
      onClose={_onClose}
      title='Add a credit card'
      canCloseOutside={true}
      verticalAlignment='center'
      width={620}
    >
      {isLoadingPaymentElement && <SpinningLoader size={50} message='' />}

      <PaymentElement
        className='mt-medium'
        onChange={() => setPaymentMethodError(false)}
        onReady={() => setIsLoadingPaymentElement(false)}
      />

      <div className='mt-large'>
        <Checkbox onChange={() => setIsDefaultCard(!isDefaultCard)}>Make this my default payment method</Checkbox>
      </div>

      {paymentMethodError && <PaymentMethodError />}

      <ActionModalFooter className='mt-medium'>
        <Group justify='end'>
          <Button size='md' variant='subtle' onClick={_onClose}>
            Cancel
          </Button>
          <Button size='md' onClick={_handleSubmit} loading={isLoading}>
            Add card
          </Button>
        </Group>
      </ActionModalFooter>
    </ActionModal>
  );
};

export default AddCreditCardModal;
