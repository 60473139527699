import { Alert, Anchor, Text } from '@good/ui/core';
import React from 'react';
import { CANCEL_SUBSCRIPTION_LINKS } from 'views/account-management/subscription-management/utils/constants';

const WarningSection: React.FC = () => {
  return (
    <Alert title='Warning' color='red'>
      <Text>
        By cancelling your subscription, all team members in your organisation will lose access to GoodHuman at the end
        of your billing cycle. We recommend you&nbsp;
        <Anchor href={CANCEL_SUBSCRIPTION_LINKS.EXPORT_DATA_LINK} target='_blank'>
          export your data
        </Anchor>
        &nbsp;before cancelling.
      </Text>
    </Alert>
  );
};

export default WarningSection;
