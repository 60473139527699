import React from 'react';
import { intervalToDuration } from 'date-fns';

import { Text, Table } from '@good/ui/core';
import { type TimesheetShiftComponent } from 'utilities/react-trpc';
import { ShiftTimeRangeLabel } from '../shift-time-range-label';

type ShiftComponentRowProps = {
  shiftComponent: TimesheetShiftComponent;
};

export const ShiftComponentRow = ({ shiftComponent }: ShiftComponentRowProps) => {
  const start = new Date(shiftComponent.startDateTime);
  const end = new Date(shiftComponent.endDateTime);
  const duration = intervalToDuration({ start, end });
  const durationLabel = `${duration.hours ? `${duration.hours}h` : ''} ${
    duration.minutes ? `${duration.minutes}m` : ''
  }`;

  return (
    <Table.Tr>
      <Table.Td>
        <ShiftTimeRangeLabel shift={shiftComponent} lh={1.2} size='xs' fw={700} />
      </Table.Td>
      <Table.Td>
        <Text lh={1.2} size='xs'>
          {shiftComponent.workType.name}
        </Text>
      </Table.Td>
      <Table.Td>
        <Text lh={1.2} size='xs' fw={700}>
          {durationLabel}
        </Text>
      </Table.Td>
    </Table.Tr>
  );
};
