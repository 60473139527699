import React from 'react';
import { intervalToDuration } from 'date-fns';

import { Table, type TableTdProps, Text } from '@good/ui/core';
import { type TimesheetShift } from 'utilities/react-trpc';

type DurationCellProps = TableTdProps & {
  shift: TimesheetShift;
};

export const DurationCell = ({ shift, ...props }: DurationCellProps) => {
  const duration = shift.duration && intervalToDuration({ start: new Date(0), end: shift.duration * 60 * 60 * 1000 });
  const durationLabel = duration
    ? `${duration.hours ? `${duration.hours}h` : ''} ${duration.minutes ? `${duration.minutes}m` : ''}`
    : '-';

  return (
    <Table.Td {...props}>
      <Text size='sm' fw={600}>
        {durationLabel}
      </Text>
    </Table.Td>
  );
};
