import React, { Fragment, useCallback, useState } from 'react';
import { Stack, Text, Box, Divider } from '@good/ui/core';

import { useNavBarSections } from './use-navbar-sections';
import { NavbarLink } from './navbar-link';
import type { NavChildLinkItem, NavLinkItem, NavLinkItemWithChildItems, NavLinkItemWithoutChildItems } from '../types';
import { useMatchedNavItem } from './use-matched-nav-item';
import { NavbarLinkWithChildren } from './navbar-link-with-children';
import { useSet } from '@good/ui/hooks';

type NavBarLinksProps = {
  onLinkSelect: () => void;
};

function hasChildItems(item: NavLinkItem): item is NavLinkItemWithChildItems {
  return 'childItems' in item;
}

export const NavbarLinks = ({ onLinkSelect }: NavBarLinksProps) => {
  const [active, setActive] = useState<NavLinkItemWithoutChildItems | NavChildLinkItem | undefined>(undefined);
  const collapsedItems = useSet<string>([]);

  const onMatchedNavItem = useCallback((navItem: NavLinkItemWithoutChildItems | NavChildLinkItem | undefined) => {
    setActive(navItem);
  }, []);

  const navbarSections = useNavBarSections();
  useMatchedNavItem(navbarSections, onMatchedNavItem);

  const toggleCollapsedItem = (item: NavLinkItemWithChildItems) => {
    if (collapsedItems.has(item.label)) {
      collapsedItems.delete(item.label);
    } else {
      collapsedItems.add(item.label);
    }
  };

  return (
    <Stack>
      {navbarSections.map((section, index) => {
        const { items } = section;
        const isLastSection = index === navbarSections.length - 1;

        return (
          <Fragment key={section.key}>
            <Box px='sm'>
              <Stack gap={4}>
                {section.title && (
                  <Text tt='uppercase' c='dimmed' fw={700} fz='xs' ml='md' my={6}>
                    {section.title}
                  </Text>
                )}
                {items.map((item) => {
                  if (hasChildItems(item)) {
                    return (
                      <NavbarLinkWithChildren
                        key={item.label}
                        item={item}
                        onClickLink={(item) => {
                          setActive(item);
                          onLinkSelect();
                        }}
                        open={collapsedItems.has(item.label)}
                        toggle={toggleCollapsedItem}
                        activeItem={active}
                      />
                    );
                  }

                  return (
                    <NavbarLink
                      key={item.label}
                      isActive={item.label === active?.label}
                      item={item}
                      onClick={(item) => {
                        // setActive will be called again by useMatchedNavItem - but this provides a smoother experience than waiting for the useMatchedNavItem hook to update the active item
                        setActive(item);
                        onLinkSelect();
                      }}
                    />
                  );
                })}
              </Stack>
            </Box>
            {!isLastSection && <Divider />}
          </Fragment>
        );
      })}
    </Stack>
  );
};
