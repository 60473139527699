import React from 'react';

import { Group, Table, type TableTdProps, Text } from '@good/ui/core';
import { NoteText, User, Users } from '@good/icons';
import { type TimesheetShift } from 'utilities/react-trpc';
import { ServiceType } from 'utilities/enum-utils';

type ServiceCellProps = TableTdProps & {
  shift: TimesheetShift;
};

export const ServiceCell = ({ shift }: ServiceCellProps) => {
  const { serviceDetails } = shift;
  const icon = (() => {
    if (serviceDetails?.serviceType === ServiceType.GROUP) {
      return <Users aria-label='Group service icon' />;
    }
    if (serviceDetails?.serviceType === ServiceType.COORDINATION) {
      return <NoteText aria-label='Support coordination icon' />;
    }
    return <User aria-label='Support service icon' />;
  })();

  return (
    <Table.Td>
      <Group gap={6}>
        {icon}
        <Text size='sm' fw={500}>
          {serviceDetails?.serviceName}
        </Text>
      </Group>
    </Table.Td>
  );
};
