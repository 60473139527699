import React from 'react';

import { Box, Group, Table, Text, type TableTdProps, Avatar } from '@good/ui/core';
import { type TimesheetShift } from 'utilities/react-trpc';

type TeamMemberCellProps = TableTdProps & {
  shift: TimesheetShift;
};

export const TeamMemberCell = ({ shift, ...props }: TeamMemberCellProps) => {
  const { supportWorkerDetails } = shift;
  const hasEmployeeNumber =
    supportWorkerDetails?.employeeNumber !== null && supportWorkerDetails?.employeeNumber !== '';

  return (
    <Table.Td {...props}>
      <Group gap={8}>
        <Avatar
          name={supportWorkerDetails?.displayName ?? undefined}
          radius='md'
          size={32}
          src={supportWorkerDetails?.avatarUrl ?? undefined}
        />
        <Box>
          <Text fw={600} size='sm' lh={1.2}>
            {supportWorkerDetails?.firstName} {supportWorkerDetails?.lastName}
          </Text>
          {hasEmployeeNumber && (
            <Text fz={11} c='dimmed' fw={500} lh={1.2}>
              ID: {supportWorkerDetails?.employeeNumber ?? '-'}
            </Text>
          )}
        </Box>
      </Group>
    </Table.Td>
  );
};
