import React from 'react';
import { Group, Button, rem, Box, Text } from '@mantine/core';
import { ChevronDown } from '@good/icons';
/**
 * The button attached to the filter component.
 */
export const FilterButton = React.forwardRef(({ label, selectedLabel, opened, ...buttonProps }, ref) => {
    return (<Button ref={ref} size='md' rightSection={<Box ml={-6}>
            <Text fz='sm' style={{
                transform: opened ? 'rotate(-180deg)' : 'none',
                transition: 'transform 300ms ease',
            }}>
              <ChevronDown aria-label={opened ? 'opened' : 'closed'}/>
            </Text>
          </Box>} {...buttonProps} style={{ ...buttonProps.style, borderWidth: rem(1.5) }} aria-label={`filter by ${label}`}>
        <Group gap={6} wrap='nowrap'>
          <span style={{ fontWeight: 600 }}>{label}</span>
          {selectedLabel && <span style={{ opacity: 0.32 }}>|</span>}
          {selectedLabel && (<Text fw={500} inherit component='span' truncate='end' maw={180}>
              {selectedLabel}
            </Text>)}
        </Group>
      </Button>);
});
