import React from 'react';

import { ChevronRight } from '@good/icons';
import { Box, Collapse, Flex, Text, UnstyledButton } from '@good/ui/core';
import { NavbarChildLink } from './navbar-child-link';
import { useNavbarLinkProps } from './use-navbar-link-props';
import type { NavChildLinkItem, NavLinkItemWithChildItems, NavLinkItemWithoutChildItems } from '../types';
import classes from './navbar.module.css';

type NavbarLinkProps = {
  open: boolean;
  item: NavLinkItemWithChildItems;
  toggle: (item: NavLinkItemWithChildItems) => void;
  onClickLink: (item: NavChildLinkItem) => void;
  activeItem: NavLinkItemWithoutChildItems | NavChildLinkItem | undefined;
};

export const NavbarLinkWithChildren = ({ open, item, toggle, onClickLink, activeItem }: NavbarLinkProps) => {
  const iconWithProps = React.cloneElement(item.icon, { className: classes.linkIcon, style: { color: 'inherit' } });
  const props = useNavbarLinkProps(item);
  const hasActiveChild = Boolean(item.childItems?.some((childItem) => childItem.label === activeItem?.label));
  const closedWithActiveChild = !open && hasActiveChild;

  return (
    <>
      <UnstyledButton
        onClick={() => toggle(item)}
        component='button'
        data-active={closedWithActiveChild || undefined}
        {...props}
      >
        <Flex justify='space-between' w='100%'>
          <Box>
            {iconWithProps}
            <Text component='span' fz='sm'>
              {item.label}
            </Text>
          </Box>
          <Text
            fz='sm'
            style={{
              transform: open ? 'rotate(-90deg)' : 'none',
              transition: 'transform 200ms ease',
            }}
          >
            <ChevronRight />
          </Text>
        </Flex>
      </UnstyledButton>
      {item.childItems && (
        <Collapse in={open}>
          <Flex direction='column' ml='lg'>
            {item.childItems.map((childItem) => (
              <NavbarChildLink
                key={childItem.label}
                isActive={activeItem?.label === childItem.label}
                item={childItem}
                onClick={onClickLink}
              />
            ))}
          </Flex>
        </Collapse>
      )}
    </>
  );
};
