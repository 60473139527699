import { createContext, useContext } from 'react';
import { PortalUser } from 'stores/rematch/models/auth-store';

/**
 * Portal user context, for storing the current portal user.
 */
export const PortalUserContext = createContext<PortalUser | null>(null);

/**
 * Pulls the current portal user from the global app container, using the portal user context.
 */
export const usePortalUser = (): PortalUser => {
  const portalUser = useContext(PortalUserContext);
  if (!portalUser) {
    // eslint-disable-next-line no-console -- This is a warning that we need to find bad code
    console.warn('portalUser must be set within a PortalUserProvider');
  }

  return portalUser!;
};
