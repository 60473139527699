import React from 'react';
import { format } from 'date-fns';

import { Table, type TableTdProps, Text } from '@good/ui/core';
import { type TimesheetShift } from 'utilities/react-trpc';
import { ShiftTimeRangeLabel } from './shift-time-range-label';

type DateCellProps = TableTdProps & {
  shift: TimesheetShift;
};

export const DateCell = ({ shift, ...props }: DateCellProps) => {
  return (
    <Table.Td {...props}>
      <Text fw={700} size='sm'>
        {format(new Date(shift.startDateTime), 'd LLL yyyy')}
      </Text>
      <ShiftTimeRangeLabel shift={shift} c='dimmed' size='xs' fw={500} />
    </Table.Td>
  );
};
