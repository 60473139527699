import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import type { NavLinkItemWithoutChildItems, NavSection, NavChildLinkItem } from '../types';

/**
 * Returns the matched navigation item based on the current location pathname.
 * Exact matches are prioritized over partial matches.
 * This also matches child items.
 *
 * @param navSections - An array of navigation sections.
 * @param callback - An optional callback function to be called with the matched navigation item.
 * @returns The matched navigation item or `undefined` if no match is found.
 */
export function useMatchedNavItem(
  navSections: NavSection[],
  callback?: (navItem: NavLinkItemWithoutChildItems | NavChildLinkItem | undefined) => void,
) {
  const location = useLocation();
  return useMemo(() => {
    const navLinksItems = navSections.flatMap((section) => section.items);
    const navLinkItemsWithoutChildItems = navLinksItems.filter(
      (navLinkItem): navLinkItem is NavLinkItemWithoutChildItems => !('childItems' in navLinkItem),
    );
    const navChildLinksItems = navLinksItems
      .flatMap((navLinkItem) => ('childItems' in navLinkItem ? navLinkItem.childItems : []))
      .filter((x) => x) as NavChildLinkItem[];
    const allNavLinksItems = [...navLinkItemsWithoutChildItems, ...navChildLinksItems];

    const pathname = location.pathname;

    const matchNavLinkItem = (navLinkItem: NavLinkItemWithoutChildItems | NavChildLinkItem, exact: boolean) =>
      [navLinkItem.link, ...(navLinkItem.matchPath ?? [])].some((path) => {
        return matchPath(pathname, { path, exact });
      });

    const exactMatchedLink = allNavLinksItems.find((navLinkItem) => {
      return matchNavLinkItem(navLinkItem, true);
    });

    if (exactMatchedLink) {
      // eslint-disable-next-line callback-return -- callback is optional
      callback?.(exactMatchedLink);
      return exactMatchedLink;
    }

    const matchedLink = allNavLinksItems.find((navLinkItem) => {
      return matchNavLinkItem(navLinkItem, false);
    });

    // eslint-disable-next-line callback-return -- callback is optional
    callback?.(matchedLink);
    return matchedLink;
  }, [callback, location.pathname, navSections]);
}
