import React from 'react';
import { Badge, BadgeProps, DefaultMantineColor, Group } from '@good/ui/core';
import { useTranslation } from 'react-i18next';

type StripeSubscriptionStatus =
  | 'incomplete'
  | 'incomplete_expired'
  | 'trialing'
  | 'active'
  | 'past_due'
  | 'canceled'
  | 'unpaid'
  | 'paused'
  | 'none-payg';

const badgeColors: Record<StripeSubscriptionStatus, DefaultMantineColor> = {
  incomplete: 'warning',
  incomplete_expired: 'gray',
  trialing: 'green',
  active: 'green',
  past_due: 'danger',
  canceled: 'danger',
  unpaid: 'danger',
  paused: 'gray',
  'none-payg': 'green',
};

const defaultBadgeProps: BadgeProps = {
  size: 'lg',
  radius: 'xl',
};

export const SubscriptionStatusBadge = ({
  status,
  daysLeftInTrial,
}: { status: StripeSubscriptionStatus; daysLeftInTrial?: number }) => {
  const { t } = useTranslation('', { keyPrefix: 'subscriptions' });

  if (status === 'trialing' && daysLeftInTrial)
    return (
      <Group gap='xs'>
        <Badge {...defaultBadgeProps} color={badgeColors[status]} variant='filled'>
          {t(`statusLabels.${status}`)}
        </Badge>
        <Badge {...defaultBadgeProps} color='warning' variant='outline'>
          {daysLeftInTrial} days left
        </Badge>
      </Group>
    );

  return (
    <Badge {...defaultBadgeProps} color={badgeColors[status]} variant='filled'>
      {t(`statusLabels.${status}`)}
    </Badge>
  );
};
