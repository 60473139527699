import { useCallback, useState } from 'react';

export type TableRowControl = {
  selectedRows: string[];
  inverseSelection: boolean;
  toggleRows: () => void;
  toggleRow: (id: string) => void;
  clearRows: () => void;
  hasSelectedRows: boolean;
  selectedRowsCount: number;
  allSelected: boolean;
};

export function useTableRowControl(totalCount: number | undefined): TableRowControl {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [inverseSelection, setInverseSelection] = useState(false);
  const hasSelectedRows = inverseSelection ? selectedRows.length < (totalCount ?? 0) : selectedRows.length > 0;
  const selectedRowsCount = inverseSelection ? (totalCount ?? 0) - selectedRows.length : selectedRows.length;
  const allSelected = selectedRows.length === (inverseSelection ? 0 : totalCount);

  const toggleRows = useCallback(() => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.length !== totalCount) {
        // if all rows already selected this would otherwise require a double click
        setInverseSelection((prevInverseSelection) => {
          return !prevInverseSelection;
        });
      }

      return [];
    });
  }, [totalCount]);

  const toggleRow = useCallback(
    (id: string) =>
      setSelectedRows((current) => (current.includes(id) ? current.filter((item) => item !== id) : [...current, id])),
    [],
  );

  const clearRows = useCallback(() => {
    setSelectedRows([]);
    setInverseSelection(false);
  }, []);

  return {
    selectedRows,
    inverseSelection,
    toggleRows,
    toggleRow,
    clearRows,
    hasSelectedRows,
    selectedRowsCount,
    allSelected,
  };
}
