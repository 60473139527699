import { ISubscriptionStatusInfo } from 'interfaces/subscription-interfaces';
import moment from 'moment';
import React from 'react';
import { SubscriptionStatus } from 'utilities/enum-utils';
import { CANCEL_SUBSCRIPTION_LINKS } from '../subscription-management/utils/constants';
import { Anchor, Text } from '@good/ui/core';

interface IProps {
  isSubscriptionScreen?: boolean;
  timezone: string;
  daysLeftLabel: string;
  subscriptionStatusInfo: ISubscriptionStatusInfo;
  hasSubscription?: boolean;
  hasPermission?: boolean;
}

const DescriptionSection = ({
  isSubscriptionScreen,
  daysLeftLabel,
  subscriptionStatusInfo,
  timezone,
  hasPermission,
  hasSubscription,
}: IProps) => {
  const subscriptionStatus = subscriptionStatusInfo?.subscriptionStatus;

  if (!isSubscriptionScreen)
    return (
      <Text size='large'>
        {hasSubscription ? (
          `Manage your organisation’s GoodHuman subscription, payment details and billing contacts. ${
            !hasPermission ? 'Contact your workspace’s primary owner to be granted access to this area.' : ''
          }`
        ) : (
          <Text>
            For questions about your account, contact us at{' '}
            <Anchor href='mailto:support@goodhuman.me'>support@goodhuman.me</Anchor>.
          </Text>
        )}
      </Text>
    );

  switch (subscriptionStatus) {
    case SubscriptionStatus.TRIALING:
      return (
        <Text>
          You have{' '}
          <Text span fw='bold' c='green'>
            {daysLeftLabel}
          </Text>{' '}
          remaining in your free trial
        </Text>
      );

    case SubscriptionStatus.NON_PAYG:
    case SubscriptionStatus.ACTIVE:
      return <Text>Enjoy full access to the GoodHuman workspace, business app and customer app.</Text>;

    case SubscriptionStatus.PAUSED:
      const formattedUntilDate = moment.tz(subscriptionStatusInfo?.untilDate, timezone).format('D MMM YYYY');
      return (
        <Text>
          Your subscription has been cancelled. You can still access GoodHuman until&nbsp;
          <Text span fw='bold'>
            {formattedUntilDate}.
          </Text>
        </Text>
      );

    case SubscriptionStatus.CANCELED:
      return (
        <Text>
          Your subscription has been cancelled.{' '}
          <Anchor href={`mailto:${CANCEL_SUBSCRIPTION_LINKS.SUPPORT_MAIL_LINK}`}>Contact us</Anchor> if you think this
          is a mistake.
        </Text>
      );
  }
};

export default DescriptionSection;
