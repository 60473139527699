import { Col } from 'antd';
import React from 'react';
import { TeamStatus } from 'utilities/enum-utils';
import { Text } from '@good/components';
import { Avatar } from '@good/ui/core';

type TmMgmtPersonColumnParams = {
  status: TeamStatus;
  attachmentUrl: string;
  firstName: string;
  lastName: string;
  locality?: string;
  email?: string;
};

export const TmMgmtPersonColumn = ({
  status,
  attachmentUrl,
  firstName,
  lastName,
  locality,
  email,
}: TmMgmtPersonColumnParams) => {
  const fontColor = status !== TeamStatus.ENABLED ? 'tertiary' : 'black';
  const textColor = status !== TeamStatus.REMOVED ? fontColor : 'red-dark';
  const textOpacity = status === TeamStatus.REMOVED ? 'opacity-60' : 'opacity-100';

  return (
    <Col span={8}>
      <div className='align-center flex-row'>
        <div className='mr-medium align-center flex-row'>
          <Avatar src={attachmentUrl} style={{ opacity: status !== TeamStatus.ENABLED ? 0.5 : 1 }} />
          {status === TeamStatus.REMOVED && (
            <div
              style={{
                position: 'absolute',
                zIndex: 10,
                marginLeft: '18px',
                width: '4px',
                backgroundColor: '#FAFAFA',
                transform: 'rotate(45deg)',
                height: '60px',
              }}
            />
          )}
        </div>
        <div>
          <Text size='md' className={`text-color-${textColor} font-bold ${textOpacity}`}>
            {`${firstName} ${lastName}`}
          </Text>
          <br />
          <Text className={`text-color-${textColor} ${textOpacity}`}>
            {`${locality ?? 'Locality not set'} · ${email ?? 'Email not set'}`}
          </Text>
        </div>
      </div>
    </Col>
  );
};
