import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Group, Paper, type PaperProps, Text } from '@good/ui/core';

type TimesheetsTableFooterProps = {
  totalCount: number | undefined;
  h?: PaperProps['h'];
};

export const TimesheetsTableFooter = ({ totalCount, h = 56 }: TimesheetsTableFooterProps): JSX.Element => {
  const { t } = useTranslation('', { keyPrefix: 'payments.timesheets.table.footer' });

  return (
    <Paper bg='gray.1' h={h} py='md' px='xl'>
      <Flex justify='end'>
        <Group>
          <Text fw={700}>{t('total', { count: totalCount ?? 0 })}</Text>
        </Group>
      </Flex>
    </Paper>
  );
};
