import React from 'react';
import { Divider, Menu, ScrollAreaAutosize, Stack } from '@mantine/core';
import { SelectDateRangeItem } from './select-date-range-item';
export const SelectDatesRangeOptionMenu = ({ rangeOptions, value, onChange, scrollAreaHeight, title, }) => {
    if (!rangeOptions || rangeOptions.length === 0) {
        return null;
    }
    return (<Stack gap={0} w={144}>
      {title && (<>
          <Menu.Label fw={700} c='dark' px='md' py='xs' fz='sm'>
            {title}
          </Menu.Label>
          <Divider />
        </>)}
      <ScrollAreaAutosize h={scrollAreaHeight} p={6}>
        {rangeOptions.map((rangeOption, index) => (<SelectDateRangeItem rangeOption={rangeOption} value={value} onChange={onChange} first={index === 0}/>))}
      </ScrollAreaAutosize>
    </Stack>);
};
