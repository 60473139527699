import React from 'react';

import { Container, type ContainerProps } from '@good/ui/core';
import { headerLayout } from './app-container';

type PageContainerProps = ContainerProps & {
  children: React.ReactNode;
  viewHeight?: boolean;
};

export const PageContainer = ({ children, viewHeight, ...containerProps }: PageContainerProps) => {
  const h = viewHeight ? `calc(100vh - ${headerLayout.height}px)` : '100%';

  return (
    <Container
      px={{ base: 'md', sm: 'lg', md: 'xl' }}
      py={{ base: 'md', md: 'xl' }}
      h={h}
      w='100%'
      maw={2000}
      id='content-container'
      data-testid='content-container'
      {...containerProps}
    >
      {children}
    </Container>
  );
};
