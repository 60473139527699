import React, { useMemo } from 'react';

import { Table, type TableTdProps, Text } from '@good/ui/core';
import { type IShiftComponent } from 'interfaces/booking-interfaces';
import { type TimesheetShift } from 'utilities/react-trpc';

type TimeTypeCellProps = TableTdProps & {
  shift: TimesheetShift;
};

const useTimeTypes = (shiftComponents: IShiftComponent[] | undefined): string[] => {
  return useMemo(
    () =>
      shiftComponents
        ?.map(({ workType }) => {
          switch (workType.shiftType) {
            case 'sleepover':
              return {
                label: workType.name,
                weight: 1,
              };

            case 'disturbance':
              return {
                label: workType.name,
                weight: 2,
              };

            case 'normal':
              return {
                label: workType.name,
                weight: 4,
              };

            default:
              return {
                label: workType.name,
                weight: 5,
              };
          }
        })
        .sort((a, b) => a.weight - b.weight)
        .map((workType) => workType.label) ?? [],
    [shiftComponents],
  );
};

export const TimeTypeCell = ({ shift }: TimeTypeCellProps) => {
  const timeTypes: string[] = useTimeTypes(shift.shiftComponents);

  return (
    <Table.Td>
      <Text size='sm' fw={500}>
        {timeTypes[0]}
      </Text>
      {timeTypes.length > 1 ? <Text fz={11} c='dimmed'>{` (+${timeTypes.length - 1} more)`}</Text> : undefined}
    </Table.Td>
  );
};
