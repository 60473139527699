import React from 'react';

import { Table, type TableTdProps, Text } from '@good/ui/core';
import { type TimesheetShift } from 'utilities/react-trpc';

type TravelCellProps = TableTdProps & {
  shift: TimesheetShift;
};

export const TravelCell = ({ shift, ...props }: TravelCellProps) => {
  const travelDistanceTotal = shift.travelBreakdown.travelDistanceTotal;
  const distance = travelDistanceTotal && travelDistanceTotal > 0 ? `${Math.round(travelDistanceTotal)}km` : '-';

  return (
    <Table.Td {...props}>
      <Text fw={500} size='sm'>
        {distance}
      </Text>
    </Table.Td>
  );
};
