import { useMemo } from 'react';
import { addWeeks, addMonths, addMilliseconds } from 'date-fns';

import { reactTrpc } from 'utilities/react-trpc';
import { usePortalUser } from 'utilities/react-hooks/use-portal-user';
import { DatesRangeValue } from '@good/ui/dates';

export function incrementPayPeriod(
  payPeriodStartDate: Date,
  payPeriodDuration: 'WEEKLY' | 'FORTNIGHTLY' | 'TWICEMONTHLY' | 'FOURWEEKLY' | 'MONTHLY' | 'QUARTERLY',
  type: 'next' | 'previous',
): Date {
  const isNext = type === 'next';

  switch (payPeriodDuration) {
    case 'WEEKLY':
      return addWeeks(payPeriodStartDate, isNext ? 1 : -1);
    case 'FORTNIGHTLY':
      return addWeeks(payPeriodStartDate, isNext ? 2 : -2);
    case 'TWICEMONTHLY':
      return addWeeks(payPeriodStartDate, isNext ? 2 : -2);
    case 'FOURWEEKLY':
      return addWeeks(payPeriodStartDate, isNext ? 4 : -4);
    case 'MONTHLY':
      return addMonths(payPeriodStartDate, isNext ? 1 : -1);
    case 'QUARTERLY':
      return addMonths(payPeriodStartDate, isNext ? 3 : -3);
    default:
      throw new Error(`Unknown pay period duration: ${payPeriodDuration}`);
  }
}

export function getCurrentPayPeriodEndDate(
  payPeriodStartDate: Date,
  payPeriodDuration: 'WEEKLY' | 'FORTNIGHTLY' | 'TWICEMONTHLY' | 'FOURWEEKLY' | 'MONTHLY' | 'QUARTERLY',
  calculateTo = new Date(),
): Date {
  let currentPayPeriod = payPeriodStartDate;
  while (currentPayPeriod < calculateTo) {
    const previousPayPeriod = currentPayPeriod;
    currentPayPeriod = incrementPayPeriod(currentPayPeriod, payPeriodDuration, 'next');
    if (currentPayPeriod <= previousPayPeriod) {
      throw new Error(`This pay period cannot be calculated: ${payPeriodDuration}`);
    }
  }

  return currentPayPeriod;
}

export function usePayPeriodDatesRanges(
  callback?: (currentPayPeriodRange: DatesRangeValue, previousPayPeriodRange: DatesRangeValue) => void,
  calculateTo = new Date(),
): {
  currentPayPeriodRange: DatesRangeValue;
  previousPayPeriodRange: DatesRangeValue;
  isLoading: boolean;
} {
  const portalUser = usePortalUser();
  const { data, isLoading } = reactTrpc.serviceProvider.payPeriod.fetch.useQuery({
    serviceProviderId: portalUser.serviceProviderId,
  });

  return useMemo(() => {
    const payPeriodDuration = data?.payPeriodDuration;
    const payPeriodStartDate = data?.payPeriodStartDate;
    let currentPayPeriodEndDate: Date | null = null;
    let currentPayPeriodStartDate: Date | null = null;
    let previousPayPeriodEndDate: Date | null = null;
    let previousPayPeriodStartDate: Date | null = null;

    if (payPeriodDuration && payPeriodStartDate) {
      currentPayPeriodEndDate = getCurrentPayPeriodEndDate(payPeriodStartDate, payPeriodDuration, calculateTo);
      currentPayPeriodStartDate = incrementPayPeriod(currentPayPeriodEndDate, payPeriodDuration, 'previous');
      previousPayPeriodEndDate = currentPayPeriodStartDate;
      previousPayPeriodStartDate = incrementPayPeriod(previousPayPeriodEndDate, payPeriodDuration, 'previous');

      currentPayPeriodEndDate = addMilliseconds(currentPayPeriodEndDate, -1);
      previousPayPeriodEndDate = addMilliseconds(previousPayPeriodEndDate, -1);
    }

    const currentPayPeriodRange: DatesRangeValue = [currentPayPeriodStartDate, currentPayPeriodEndDate];
    const previousPayPeriodRange: DatesRangeValue = [previousPayPeriodStartDate, previousPayPeriodEndDate];

    if (callback && !isLoading) {
      // eslint-disable-next-line callback-return -- still expects a return value
      callback(currentPayPeriodRange, previousPayPeriodRange);
    }

    return {
      currentPayPeriodRange,
      previousPayPeriodRange,
      isLoading,
    };
  }, [data, isLoading]);
}
