import React from 'react';
import { useTranslation } from 'react-i18next';

import { reactTrpc } from 'utilities/react-trpc';
import { notifications } from '@good/ui/notifications';
import { Check, Cross } from '@good/icons';

export function useExportTimesheets(opts?: { onSuccess?: () => void; onError?: () => void }): ReturnType<
  typeof reactTrpc.timesheets.export.useMutation
> {
  const { t } = useTranslation('', { keyPrefix: 'payments.timesheets.actions.export' });

  return reactTrpc.timesheets.export.useMutation({
    onSuccess: (data) => {
      const resourceUrl = data.exportedResources[0]?.resourceUrl;
      if (!resourceUrl) {
        notifications.show({
          title: t('error.title'),
          message: t('error.message'),
          color: 'red',
          icon: <Cross />,
        });
        return;
      }
      const link = document.createElement('a');
      link.href = resourceUrl;
      link.setAttribute('download', 'timesheets.csv');
      document.body.appendChild(link);
      link.click();
      opts?.onSuccess?.();
      notifications.show({
        title: t('success.title'),
        message: t('success.message'),
        color: 'green',
        icon: <Check />,
      });
    },
    onError: () => {
      notifications.show({
        title: t('error.title'),
        message: t('error.message'),
        color: 'red',
        icon: <Cross />,
      });
      opts?.onError?.();
    },
  });
}
