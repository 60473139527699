import React from 'react';
import { Filter } from '@good/ui/templates';
import { Users, User, Note2Text, Home, Search } from '@good/icons';
import { ServiceType, ServiceStatus } from 'utilities/enum-utils';
import { StatusPill } from './components/status-pill';
import { TextInput, Group } from '@good/ui/core';
import { useTranslation } from 'react-i18next';
import { ServiceDepartmentFilter } from 'components/filters/service-department-filter';

const serviceTypes = [
  { icon: <Users />, label: 'Group service', key: ServiceType.GROUP },
  { icon: <User />, label: 'Support service', key: ServiceType.INDIVIDUAL },
  { icon: <Note2Text />, label: 'Support coordination', key: ServiceType.COORDINATION },
  { icon: <Home />, label: 'Supported independent living', key: ServiceType.INDEPENDENT_LIVING },
];

const serviceStatus = [
  { label: 'Published', key: ServiceStatus.PUBLISHED, leftSection: <StatusPill status={ServiceStatus.PUBLISHED} /> },
  { label: 'Draft', key: ServiceStatus.DRAFT, leftSection: <StatusPill status={ServiceStatus.DRAFT} /> },
  {
    label: 'Unpublished',
    key: ServiceStatus.UNPUBLISHED,
    leftSection: <StatusPill status={ServiceStatus.UNPUBLISHED} />,
  },
  { label: 'Archived', key: ServiceStatus.ARCHIVED, leftSection: <StatusPill status={ServiceStatus.ARCHIVED} /> },
];

type FiltersPanelProps = {
  searchTerm: string;
  onSearchChange: (value: string) => void;
  onServiceTypeChange: (value: string[]) => void;
  onServiceDepartmentChange: (value: string[]) => void;
  onServiceStatusChange: (value: string[]) => void;
};

export const FiltersPanel = ({
  searchTerm,
  onSearchChange,
  onServiceTypeChange,
  onServiceDepartmentChange,
  onServiceStatusChange,
}: FiltersPanelProps) => {
  const { t } = useTranslation('', { keyPrefix: 'services.listing.table' });
  return (
    <Group py='sm'>
      <TextInput
        leftSection={<Search />}
        placeholder='Search'
        value={searchTerm}
        onChange={(e) => onSearchChange(e.target.value)}
        wrapperProps={{ variant: 'no-label' }}
      />
      <Filter items={serviceTypes} label={t('types')} title={t('typeTitle')} onChange={onServiceTypeChange} />
      <Filter
        items={serviceStatus}
        label={t('status')}
        hideLabels
        onChange={onServiceStatusChange}
        title={t('statusTitle')}
      />
      <ServiceDepartmentFilter onChange={onServiceDepartmentChange} />
    </Group>
  );
};
